<template>
  <div class="pc">
    <div class="bigbox">
      <div class="titles">
        <span>{{ $t('seller.home.mayWallet') }}</span>
      </div>
      <div class="sihezi">
        <div class="sitem">
          <img src="../../../assets/img/seller-money-lan.png" />
          <div class="rightbox">
            <span>{{ wallet.payment_commission }}</span>
            <span>{{ $t('seller.mayWallet.paymentAndCommissionBalance') }}</span>
          </div>
        </div>
        <div class="sitem">
          <img src="../../../assets/img/seller-money-cheng.png" />
          <div class="rightbox">
            <span>{{ wallet.balance }}</span>
            <span>{{ $t('seller.mayWallet.walletBalance') }}</span>
          </div>
        </div>
        <div class="sitem" @click="showpaytc">
          <span>+</span>
          <span>{{ $t('seller.mayWallet.cryptoWalletRecharge') }}</span>
        </div>
      </div>
      <div class="tabbox">
        <div class="histoy">
          <span>{{ $t('seller.mayWallet.walletRechargeHistory') }}</span>
        </div>
        <div class="tabels">
          <el-table :data="rechargeData" style="width: 100%">
            <el-table-column type="index" label="#" width="120" />
            <el-table-column prop="created_at" :label="$t('seller.appliedRefundRequest.date')" width="240" />
            <el-table-column prop="conversion_amount" :label="$t('seller.appliedRefundRequest.amount')" width="240" />
            <el-table-column prop="payment_name" :label="$t('seller.paymentHistory.paymentMethod')" width="250" />
            <el-table-column prop="payment_address" :label="$t('seller.paymentHistory.paymentAddress')" width="480" />
            <el-table-column prop="status" :label="$t('seller.paymentHistory.approval')" width="180">
              <template #default="scope">
                <span class="span1" v-if="scope.row.status_color == 1">{{ scope.row.status }}</span>
                <span class="span2" v-if="scope.row.status_color == 2">{{ scope.row.status }}</span>
                <span class="span3" v-if="scope.row.status_color == 3">{{ scope.row.status }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- <div class="fenye">
                <el-pagination background layout="prev, pager, next" :total="1000" />
            </div> -->
      <!-- 弹窗 -->
      <div class="paytc" @click="guanbiTc" v-show="showtc">
        <div class="tchezi" id="tchezi">
          <div class="paytitle">
            <span>{{ $t('seller.mayWallet.cryptoWalletRecharge') }}</span>
          </div>
          <div class="payselectbox">
            <div v-for="item in peyList" :key="item.id">
              <div class="payselect" :class="payid == item.id ? 'dongtai' : ''" @click="seletcPay(item.id)">
                <img :src="item.payment_img" />
                <span>{{ item.payment_name }}</span>
              </div>
            </div>
          </div>
          <div class="zhifuma">
            <div class="adresbox">
              <span>{{ $t('seller.makeCryptoPayment.address') }}</span>
              <span @click="copyText">{{ $t('seller.product.copy') }}</span>
              <span>{{ payAddress }}</span>
            </div>
            <div class="qrcode">
              <span class="qrtext">{{ $t('seller.makeCryptoPayment.qrCode') }}</span>
              <div class="ewmbox">
                <div class="erweima">
                  <img :src="qrCode" />
                </div>
                <div class="addrbox">
                  <span>{{ $t('seller.makeCryptoPayment.paymentAddress') }}</span>
                  <span>{{ payAddress }}</span>
                </div>
              </div>
              <div class="miaosu">
                <span>{{ $t('seller.makeCryptoPayment.description') }}</span>
                <input type="text" :placeholder="describe" v-model="describes" />
              </div>
            </div>
          </div>
          <div class="amountbox">
            <div>
              <span>{{ $t('seller.makeCryptoPayment.amount') }}</span>
              <span>*</span>
            </div>
            <input type="number" placeholder="0" v-model="amount" />
          </div>
          <div class="qrbtn">
            <div class="querenbox" @click="confirm">
              <span>{{ $t('seller.makeCryptoPayment.confirmation') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="phone">
    <div class="bigbox">
      <div class="titles">
        <span>{{ $t('seller.home.mayWallet') }}</span>
      </div>
      <div class="sihezi">
        <div class="sitem">
          <img src="../../../assets/img/seller-money-lan.png" />
          <div class="rightbox">
            <span>{{ wallet.payment_commission }}</span>
            <span>{{ $t('seller.mayWallet.paymentAndCommissionBalance') }}</span>
          </div>
        </div>
        <div class="sitem">
          <img src="../../../assets/img/seller-money-cheng.png" />
          <div class="rightbox">
            <span>{{ wallet.balance }}</span>
            <span>{{ $t('seller.mayWallet.walletBalance') }}</span>
          </div>
        </div>
        <div class="sitem" @click="showpaytc">
          <span>+</span>
          <span>{{ $t('seller.mayWallet.cryptoWalletRecharge') }}</span>
        </div>
      </div>
      <div class="tabbox">
        <div class="histoy">
          <span>{{ $t('seller.mayWallet.walletRechargeHistory') }}</span>
        </div>
        <div class="tabels">
          <el-table :data="rechargeData" style="width: 100%" :style="{ fontSize: '24px' }">
            <el-table-column prop="created_at" :label="$t('seller.appliedRefundRequest.date')">
              <template #default="scope">
                <p>{{ scope.row.created_at }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="conversion_amount" :label="$t('seller.appliedRefundRequest.amount')">
              <template #default="scope">
                <p>{{ scope.row.conversion_amount }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="payment_name" :label="$t('seller.paymentHistory.paymentMethod')">
              <template #default="scope">
                <p>{{ scope.row.payment_name }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="payment_address" :label="$t('seller.paymentHistory.paymentAddress')">
              <template #default="scope">
                <p>{{ scope.row.payment_address }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="status" :label="$t('seller.paymentHistory.approval')">
              <template #default="scope">
                <span class="span1" v-if="scope.row.status_color == 1">{{ scope.row.status }}</span>
                <span class="span2" v-if="scope.row.status_color == 2">{{ scope.row.status }}</span>
                <span class="span3" v-if="scope.row.status_color == 3">{{ scope.row.status }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 弹窗 -->
      <div class="paytc" @click="guanbiTc" v-show="showtc">
        <div class="tchezi" id="tchezi">
          <div class="paytitle">
            <span>{{ $t('seller.mayWallet.cryptoWalletRecharge') }}</span>
          </div>
          <div class="payselectbox">
            <div v-for="item in peyList" :key="item.id" class="paybox">
              <div class="payselect" :class="payid == item.id ? 'dongtai' : ''" @click="seletcPay(item.id)">
                <img :src="item.payment_img" />
                <span>{{ item.payment_name }}</span>
              </div>
            </div>
          </div>
          <div class="zhifuma">
            <div class="adresbox">
              <span>{{ $t('seller.makeCryptoPayment.address') }}</span>
              <span @click="copyText">{{ $t('seller.product.copy') }}</span>
              <span>{{ payAddress }}</span>
            </div>
            <div class="qrcode">
              <span class="qrtext">{{ $t('seller.makeCryptoPayment.qrCode') }}</span>
              <div class="ewmbox">
                <div class="erweima">
                  <img :src="qrCode" />
                </div>
                <div class="addrbox">
                  <span>{{ $t('seller.makeCryptoPayment.paymentAddress') }}</span>
                  <span>{{ payAddress }}</span>
                </div>
              </div>
              <div class="miaosu">
                <span>{{ $t('seller.makeCryptoPayment.description') }}</span>
                <input type="text" :placeholder="describe" v-model="describes" />
              </div>
            </div>
          </div>
          <div class="amountbox">
            <div>
              <span>{{ $t('seller.makeCryptoPayment.amount') }}</span>
              <span>*</span>
            </div>
            <input type="number" placeholder="0" v-model="amount" />
          </div>
          <div class="qrbtn">
            <div class="querenbox" @click="confirm">
              <span>{{ $t('seller.makeCryptoPayment.confirmation') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paymentMethod } from '@/api/api'
import { sellerWallet, rechargeList, rechargeAdd } from '@/api/sellerapi'
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      wallet: {}, //钱包数据
      rechargeData: [], //充值数据
      showtc: false, //支付弹窗
      peyList: [], //支付方式列表
      payid: '', //支付方式id
      qrCode: '', //二维码
      payAddress: '', //支付地址
      describe: '', //描述
      describes: '', //输入的描述
      amount: '', //金额
    }
  },
  mounted() {
    this.getWalletData()
    this.getRecharge()
    this.getPayMethod()
  },
  methods: {
    // 显示支付弹窗
    showpaytc() {
      this.showtc = true
    },
    // 获取钱包统计
    async getWalletData() {
      const { data: res } = await sellerWallet()
      if (res.status == 200) {
        this.wallet = res.success
        console.log(res)
      } else {
        ElMessage.error(res.message)
      }
    },
    // 获取充值记录
    async getRecharge() {
      const { data: res } = await rechargeList()
      if (res.status == 200) {
        this.rechargeData = res.success
        console.log(res)
      } else {
        ElMessage.error(res.message)
      }
    },
    // 获取支付方式
    async getPayMethod() {
      const { data: res } = await paymentMethod()
      this.peyList = res.success
      this.payid = res.success[0].id
      this.qrCode = res.success[0].qr_code
      this.payAddress = res.success[0].payment_address
      this.describe = res.success[0].describe
      console.log(res)
    },
    // 选择支付方式
    seletcPay(payid) {
      var _this = this
      if (this.peyList.length)
        for (var i = 0; i < this.peyList.length; i++) {
          if (payid == this.peyList[i].id) {
            this.qrCode = this.peyList[i].qr_code
            this.payAddress = this.peyList[i].payment_address
            this.describe = this.peyList[i].describe
          } else if (payid == this.peyList[this.peyList.length - 1].id) {
            _this.open_win()
          }
        }
    },
    open_win() {
      window.open('https://openocean.banxa.com/')
    },
    // 复制地址
    copyText() {
      this.showtc = false
      this.$copyText(this.payAddress).then((e) => {
        ElMessage.success('Copy Success')
      })
    },
    // 遮罩层显示隐藏
    guanbiTc(e) {
      if (e.currentTarget === e.target) {
        this.showtc = false
        this.balance = ''
        this.amount = ''
      }
    },
    // 确认充值
    async confirm() {
      this.showtc = false
      if (this.amount == '') {
        return ElMessage.error('Please enter the amount')
      }
      const { data: res } = await rechargeAdd({
        payment_id: this.payid,
        recharge_amount: this.amount,
        describe: this.describes,
      })
      if (res.status == 200) {
        this.amount = ''
        this.describes = ''
        this.getWalletData()
        this.getRecharge()
        ElMessage.success(res.message)
      } else {
        ElMessage.error(res.message)
      }
    },
  },
}
</script>

<style lang="less" scoped>
// 横屏
@media all and (orientation: landscape) {
  .pc {
    display: block !important;
  }
  .phone {
    display: none !important;
  }
}

/* 竖屏*/
@media all and (orientation: portrait) {
  .pc {
    display: none !important;
  }
  .phone {
    display: block !important;
  }
}

.pc {
  .bigbox {
    width: 100%;
    box-sizing: border-box;
    padding: 0 25px;
    .titles {
      width: 100%;
      height: 60px;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      line-height: 60px;
    }
    .sihezi {
      width: 100%;
      height: 195px;
      display: flex;
      justify-content: space-between;
      .sitem {
        width: 480px;
        height: 195px;
        border: 1px solid #dfdfdf;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 60px;
          height: 60px;
        }
        .rightbox {
          display: flex;
          flex-direction: column;
          margin-left: 35px;
          text-align: left;
          & > span:nth-child(1) {
            font-size: 30px;
            font-family: Arial;
            font-weight: bold;
            color: #ffffff;
          }
          & > span:nth-child(2) {
            font-size: 14px;
            font-family: Arial;
            color: #ffffff;
            margin-top: 10px;
          }
        }
        & > span:nth-child(1) {
          width: 60px;
          height: 60px;
          background: #cacaca;
          border-radius: 50%;
          font-size: 48px;
          color: #fff;
          text-align: center;
          line-height: 60px;
        }
        & > span:nth-child(2) {
          font-size: 18px;
          margin-left: 35px;
        }
      }
      .sitem:nth-child(1) {
        background: #00adf1;
      }
      .sitem:nth-child(2) {
        background: #f19149;
      }
      .sitem:nth-child(3),
      .sitem:nth-child(4) {
        cursor: pointer;
      }
    }
    .tabbox {
      width: 100%;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 4px;
      margin-top: 25px;
      .histoy {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: left;
        font-size: 18px;
        box-sizing: border-box;
        padding-left: 30px;
        border-bottom: 1px solid #dfdfdf;
      }
      .tabels {
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        padding: 0 30px;
        .span1 {
          display: inline-block;
          padding: 5px 10px;
          background: #0abb75;
          border-radius: 5px;
          font-size: 14px;
          color: #fff;
          margin: 10px 0px;
        }
        .span2 {
          display: inline-block;
          padding: 5px 10px;
          background: #ff3030;
          border-radius: 5px;
          font-size: 14px;
          color: #fff;
          margin: 10px 0px;
        }
        .span3 {
          display: inline-block;
          padding: 5px 10px;
          background: #eead0e;
          border-radius: 5px;
          font-size: 14px;
          color: #fff;
          margin: 10px 0px;
        }
      }
    }
    .fenye {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .paytc {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      pointer-events: auto;
      .tchezi {
        width: 780px;
        height: 650px;
        overflow: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 5px;
        box-sizing: 15px;
        padding: 0 15px;
        .paytitle {
          width: 100%;
          height: 47px;
          font-size: 18px;
          font-family: Arial;
          font-weight: 500;
          color: #000000;
          line-height: 47px;
          text-align: left;
          border-bottom: 1px solid #dcdcdc;
        }
        .payselectbox {
          width: 750px;
          height: 320px;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          padding-top: 25px;
          .payselect {
            width: 150px;
            height: 120px;
            border: 1px solid #cacaca;
            border-radius: 5px;
            font-size: 12px;
            font-family: Arial;
            font-weight: 400;
            color: #000000;
            line-height: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 10px;
            cursor: pointer;
            img {
              width: 100px;
              height: 60px;
              margin-top: 10px;
            }
          }
          .dongtai {
            border: 1px solid #00adf1 !important;
          }
        }
        .zhifuma {
          width: 750px;
          border: 1px solid #dcdcdc;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 30px 15px;
          .adresbox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            span:nth-child(2) {
              width: 110px;
              height: 35px;
              background: #00adf1;
              border-radius: 5px;
              line-height: 35px;
              color: #fff;
              cursor: pointer;
            }
            span:nth-child(3) {
              width: 530px;
              height: 35px;
              border: 1px solid #dcdcdc;
              border-radius: 5px;
              line-height: 35px;
              box-sizing: border-box;
              padding-left: 30px;
              text-align: left;
              color: #969696;
            }
          }
          .qrcode {
            margin-top: 35px;
            .qrtext {
              display: block;
              width: 100%;
              font-size: 14px;
              font-family: Arial;
              font-weight: 400;
              color: #000000;
              line-height: 30px;
              text-align: left;
            }
            .ewmbox {
              width: 510px;
              margin-left: 150px;
              margin-top: 50px;
              .erweima {
                & > span {
                  font-size: 26px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #000000;
                  line-height: 31px;
                }
                & > img {
                  width: 377px;
                  height: 377px;
                  border-radius: 15px;
                  overflow: hidden;
                  margin-top: 50px;
                }
              }
              .addrbox {
                display: flex;
                flex-direction: column;
                font-size: 26px;
                font-family: Arial;
                font-weight: 400;
                margin-top: 30px;
                span:nth-child(2) {
                  color: #969696;
                  height: 60px;
                  width: 100%;
                  word-wrap: break-word;
                  word-break: break-all;
                  overflow: hidden;
                }
              }
            }
            .miaosu {
              display: flex;
              justify-content: space-between;
              display: flex;
              align-items: center;
              margin-top: 40px;
              font-size: 14px;
              input {
                width: 530px;
                height: 44px;
                border: 1px solid #dcdcdc;
                border-radius: 5px;
                outline: none;
                box-sizing: border-box;
                padding-left: 20px;
              }
            }
          }
        }
        .amountbox {
          width: 750px;
          height: 77px;
          border: 1px solid #dcdcdc;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          box-sizing: border-box;
          padding: 0 15px;
          font-size: 14px;
          span:nth-child(2) {
            color: #ff0000;
          }
          input {
            width: 530px;
            height: 44px;
            border: 1px solid #dcdcdc;
            border-radius: 5px;
            outline: none;
            box-sizing: border-box;
            padding-left: 20px;
          }
        }
        .qrbtn {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .querenbox {
            width: 215px;
            height: 50px;
            background: #00adf1;
            border-radius: 5px;
            line-height: 50px;
            text-align: center;
            font-size: 14px;
            font-family: Arial;
            font-weight: 400;
            color: #ffffff;
            margin-top: 30px;
            margin-bottom: 30px;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.phone {
  .bigbox {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    .titles {
      width: 100%;
      height: 60px;
      font-size: 28px;
      font-weight: 600;
      text-align: left;
      line-height: 60px;
    }
    .sihezi {
      width: 100%;
      .sitem {
        width: 100%;
        height: 260px;
        border: 2px solid #e6e6e6;
        border-radius: 6px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        img {
          width: 100px;
          height: 100px;
        }
        .rightbox {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-top: 20px;
          & > span:nth-child(1) {
            font-size: 40px;
            font-family: Arial;
            font-weight: bold;
            color: #ffffff;
          }
          & > span:nth-child(2) {
            font-size: 24px;
            font-family: Arial;
            color: #ffffff;
            margin-top: 10px;
            opacity: 0.6;
          }
        }
        & > span:nth-child(1) {
          width: 100px;
          height: 100px;
          background: #cacaca;
          border-radius: 50%;
          font-size: 60px;
          font-weight: 600;
          color: #fff;
          text-align: center;
          line-height: 100px;
        }
        & > span:nth-child(2) {
          font-size: 28px;
          margin-top: 20px;
        }
      }
      .sitem:nth-child(1) {
        background: #00adf1;
      }
      .sitem:nth-child(2) {
        background: #f19149;
      }
    }
    .tabbox {
      width: 100%;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 4px;
      margin-top: 25px;
      .histoy {
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-align: left;
        font-size: 28px;
        box-sizing: border-box;
        padding-left: 30px;
        border-bottom: 2px solid #dfdfdf;
      }
      .tabels {
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        padding: 0 30px;
        .span1 {
          display: inline-block;
          padding: 5px 10px;
          background: #0abb75;
          border-radius: 5px;
          font-size: 24px;
          color: #fff;
          margin: 10px 0px;
        }
        .span2 {
          display: inline-block;
          padding: 5px 10px;
          background: #ff3030;
          border-radius: 5px;
          font-size: 24px;
          color: #fff;
          margin: 10px 0px;
        }
        .span3 {
          display: inline-block;
          padding: 5px 10px;
          background: #eead0e;
          border-radius: 5px;
          font-size: 24px;
          color: #fff;
          margin: 10px 0px;
        }
        p {
          font-size: 24px;
        }
      }
    }
    .paytc {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      pointer-events: auto;
      .tchezi {
        width: 80%;
        height: 60%;
        overflow: auto;
        position: absolute;
        top: 45%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 5px;
        box-sizing: 15px;
        padding: 0 15px;
        .paytitle {
          width: 100%;
          font-size: 28px;
          font-family: Arial;
          font-weight: 500;
          color: #000000;
          line-height: 60px;
          text-align: left;
          border-bottom: 2px solid #dcdcdc;
        }
        .payselectbox {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          box-sizing: border-box;
          padding-top: 25px;
          .paybox {
            width: 30%;
            margin-top: 20px;
            .payselect {
              width: 100%;
              border: 2px solid #cacaca;
              border-radius: 5px;
              font-size: 24px;
              font-family: Arial;
              font-weight: 400;
              color: #000000;
              line-height: 30px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 0px 0px 20px 0px;
              img {
                width: 90%;
              }
            }
            .dongtai {
              border: 2px solid #00adf1 !important;
            }
          }
        }
        .zhifuma {
          width: 100%;
          border: 2px solid #dcdcdc;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 30px 15px;
          margin-top: 20px;
          .adresbox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 24px;
            span:nth-child(2) {
              padding: 5px 10px;
              background: #00adf1;
              border-radius: 5px;
              line-height: 35px;
              color: #fff;
              cursor: pointer;
            }
            span:nth-child(3) {
              width: 70%;
              height: 60px;
              border: 2px solid #dcdcdc;
              border-radius: 5px;
              line-height: 60px;
              box-sizing: border-box;
              text-align: left;
              color: #969696;
              overflow: hidden;
            }
          }
          .qrcode {
            margin-top: 35px;
            .qrtext {
              display: block;
              width: 100%;
              font-size: 24px;
              font-family: Arial;
              font-weight: 400;
              color: #000000;
              line-height: 30px;
              text-align: left;
            }
            .ewmbox {
              width: 100%;
              .erweima {
                & > span {
                  font-size: 26px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #000000;
                  line-height: 31px;
                }
                & > img {
                  width: 60%;
                  border-radius: 15px;
                  overflow: hidden;
                  margin-top: 50px;
                }
              }
              .addrbox {
                display: flex;
                flex-direction: column;
                font-size: 26px;
                font-family: Arial;
                margin-top: 30px;
                span:nth-child(2) {
                  color: #969696;
                  height: 60px;
                  width: 100%;
                  word-wrap: break-word;
                  word-break: break-all;
                  overflow: hidden;
                }
              }
            }
            .miaosu {
              display: flex;
              justify-content: space-between;
              display: flex;
              align-items: center;
              margin-top: 40px;
              font-size: 24px;
              input {
                width: 70%;
                height: 60px;
                border: 2px solid #dcdcdc;
                border-radius: 5px;
                outline: none;
                box-sizing: border-box;
                padding-left: 20px;
              }
            }
          }
        }
        .amountbox {
          width: 100%;
          height: 80px;
          border: 2px solid #dcdcdc;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          box-sizing: border-box;
          padding: 0 15px;
          font-size: 24px;
          span:nth-child(2) {
            color: #ff0000;
          }
          input {
            width: 70%;
            height: 60px;
            border: 2px solid #dcdcdc;
            border-radius: 5px;
            outline: none;
            box-sizing: border-box;
            padding-left: 20px;
            font-size: 24px;
          }
        }
        .qrbtn {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .querenbox {
            width: 215px;
            height: 60px;
            background: #00adf1;
            border-radius: 5px;
            line-height: 60px;
            text-align: center;
            font-size: 24px;
            font-family: Arial;
            font-weight: 400;
            color: #ffffff;
            margin-top: 30px;
            margin-bottom: 30px;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>