<template>
 <!-- 贷款申请表 -->
    <div class='bodys'>
        <div class="titlex">
            <span>{{ $t('seller.loanQuotaRequestFrom.loanQuotaRequestFrom') }}</span>
        </div>
        <div class="formbox">
            <div class="formtitle">
                <span>{{ $t('seller.loanQuotaRequestFrom.requestForm') }}</span>
            </div>
            <div class="itemsbox">
                <div class="itmeinp">
                    <span>{{ $t('seller.loanQuotaRequestFrom.expectedLoanQuota') }}</span>
                    <input type="number" :placeholder="$t('seller.loanQuotaRequestFrom.expectedLoanQuota')" v-model="loanAmount">
                 </div>
                 <!-- 身份证正面 -->
                <div class="itemchooes">
                    <span>{{ $t('seller.loanQuotaRequestFrom.IDCardPhotoFront') }}</span>
                    <el-upload
                        v-model:file-list="cardFront"
                        class="upload-demo"
                        :action="uploadURL"
                        :on-success="uploadSuccess"
                        :on-error="uploadError"
                        :limit="1"
                        list-type="picture"
                        >
                        <div class="spanbox">
                            <span class="span1">{{ $t('seller.loanQuotaRequestFrom.chooseFile') }}</span>
                            <span class="span2">Browse</span>
                        </div>
                    </el-upload>
                </div>
                <!-- 身份证反面 -->
                <div class="itemchooes">
                    <span>{{ $t('seller.loanQuotaRequestFrom.IDCardPhotoBack') }}</span>
                    <el-upload
                        v-model:file-list="cardBack"
                        class="upload-demo"
                        :action="uploadURL"
                        :on-success="uploadSuccess1"
                        :on-error="uploadError"
                        :limit="1"
                        list-type="picture"
                        >
                        <div class="spanbox">
                            <span class="span1">{{ $t('seller.loanQuotaRequestFrom.chooseFile') }}</span>
                            <span class="span2">Browse</span>
                        </div>
                    </el-upload>
                </div>
                <!-- 房契 -->
                <div class="itemchooes">
                    <span>{{ $t('seller.loanQuotaRequestFrom.houseDeed') }}</span>
                    <el-upload
                        v-model:file-list="houseDeed"
                        class="upload-demo"
                        :action="uploadURL"
                        :on-success="uploadSuccess2"
                        :on-error="uploadError"
                        :limit="1"
                        list-type="picture"
                        >
                        <div class="spanbox">
                            <span class="span1">{{ $t('seller.loanQuotaRequestFrom.chooseFile') }}</span>
                            <span class="span2">Browse</span>
                        </div>
                    </el-upload>
                </div>
                <!-- 机动车所有权证 -->
                <div class="itemchooes">
                    <span>{{ $t('seller.loanQuotaRequestFrom.motorVehicleCertificate') }}</span>
                    <el-upload
                        v-model:file-list="jdcQuanzheng"
                        class="upload-demo"
                        :action="uploadURL"
                        :on-success="uploadSuccess3"
                        :on-error="uploadError"
                        :limit="1"
                        list-type="picture"
                        >
                        <div class="spanbox">
                            <span class="span1">{{ $t('seller.loanQuotaRequestFrom.chooseFile') }}</span>
                            <span class="span2">Browse</span>
                        </div>
                    </el-upload>
                </div>
                <!-- 就业证明 -->
                <div class="itemchooes">
                    <span>{{ $t('seller.loanQuotaRequestFrom.proofOfEmployment') }}</span>
                    <el-upload
                        v-model:file-list="proofEmployment"
                        class="upload-demo"
                        :action="uploadURL"
                        :on-success="uploadSuccess4"
                        :on-error="uploadError"
                        :limit="1"
                        list-type="picture"
                        >
                        <div class="spanbox">
                            <span class="span1">{{ $t('seller.loanQuotaRequestFrom.chooseFile') }}</span>
                            <span class="span2">Browse</span>
                        </div>
                    </el-upload>
                </div>
                <!-- 收入证明 -->
                <div class="itemchooes">
                    <span>{{ $t('seller.loanQuotaRequestFrom.proofOfIncome') }}</span>
                    <el-upload
                        v-model:file-list="proofIncome"
                        class="upload-demo"
                        :action="uploadURL"
                        :on-success="uploadSuccess5"
                        :on-error="uploadError"
                        :limit="1"
                        list-type="picture"
                        >
                        <div class="spanbox">
                            <span class="span1">{{ $t('seller.loanQuotaRequestFrom.chooseFile') }}</span>
                            <span class="span2">Browse</span>
                        </div>
                    </el-upload>
                </div>
                <!-- 银行结单 -->
                <div class="itemchooes">
                    <span>{{ $t('seller.loanQuotaRequestFrom.bankStatement') }}</span>
                    <el-upload
                        v-model:file-list="bankStatement"
                        class="upload-demo"
                        :action="uploadURL"
                        :on-success="uploadSuccess6"
                        :on-error="uploadError"
                        :limit="1"
                        list-type="picture"
                        >
                        <div class="spanbox">
                            <span class="span1">{{ $t('seller.loanQuotaRequestFrom.chooseFile') }}</span>
                            <span class="span2">Browse</span>
                        </div>
                    </el-upload>
                </div>
            </div>
            <div class="apply">
                <span @click="RequestForm">{{ $t('seller.loanQuotaRequestFrom.apply') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
    import {uploadURL} from "@/api/api"
    import {loanQuota} from "@/api/sellerapi"
    import { ElMessage } from 'element-plus'
    export default {
        data () {
            return {
                uploadURL: uploadURL,
                loanAmount: '', //额度
                cardFront: [], //身份证正面
                cardFrontUrl: '',
                cardBack: [], //身份证反面
                cardBackUrl: [],
                houseDeed: [], //房契
                houseDeedUrl: '',
                jdcQuanzheng: [], //机动车权证
                jdcQuanzhengUrl: '',
                proofEmployment: [], //就业证明
                proofEmploymentUrl: '',
                proofIncome: [], //收入证明
                proofIncomeUrl: '',
                bankStatement: [], //银行结单
                bankStatementUrl: ''
            }
        },
        methods:{
            // 贷款额度申请表单
            async RequestForm(){
                if(this.loanAmount == '') return ElMessage.error('Please fill in the amount')
                const {data: res} = await loanQuota({
                    loan_amount: this.loanAmount, //预期贷款额度(Expected Loan Quota) 必填
                    ID_card_photo_front: this.cardFrontUrl, //身份证照片(正面)
                    ID_card_photo_back: this.cardBackUrl, //身份证照片(背面)
                    house_deed: this.houseDeedUrl, //房契（House Deed）
                    motor_vehicle_oc: this.jdcQuanzhengUrl, //机动车所有权证（Motor Vehicle Ownership Certificate）
                    proof_of_employment: this.proofEmploymentUrl, //就业证明（Proof Of Employment）
                    proof_of_income: this.proofIncomeUrl, //收入证明（Proof Of Income）
                    bank_statement: this.bankStatementUrl //银行结单（Bank Statement）
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1500);
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 图片上传失败
            uploadError(){
                ElMessage.warning('Upload failed')
            },
            // 身份证正面上传成功
            uploadSuccess(e){
                ElMessage.success('Uploaded successfully')
                this.cardFrontUrl = e.success.file_url
            },
            // 身份证反面上传成功
            uploadSuccess1(e){
                ElMessage.success('Uploaded successfully')
                this.cardBackUrl = e.success.file_url
            },
            // 房契上传成功
            uploadSuccess2(e){
                ElMessage.success('Uploaded successfully')
                this.houseDeedUrl = e.success.file_url
            },
            // 机动车权证
            uploadSuccess3(e){
                ElMessage.success('Uploaded successfully')
                this.jdcQuanzhengUrl = e.success.file_url
            },
            // 就业证明
            uploadSuccess4(e){
                ElMessage.success('Uploaded successfully')
                this.proofEmploymentUrl = e.success.file_url
            },
            // 收入证明
            uploadSuccess5(e){
                ElMessage.success('Uploaded successfully')
                this.proofIncomeUrl = e.success.file_url
            },
            // 银行结单
            uploadSuccess6(e){
                ElMessage.success('Uploaded successfully')
                this.bankStatementUrl = e.success.file_url
            },
        }
    }
</script>
<style lang="less" scoped>
    
    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}

    .pc {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .titlex {
                width: 100%;
                height: 60px;
                font-size: 18px;
                font-weight: 600;
                text-align: left;
                line-height: 60px; 
            }
            .formbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                margin-bottom: 30px;
                .formtitle {
                    width: 100%;
                    height: 55px;
                    font-size: 18px;
                    text-align: left;
                    line-height: 55px;
                    border-bottom: 1px solid #DFDFDF;
                    box-sizing: border-box;
                    padding: 0 30px;
                }
                .itemsbox {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 30px;
                    .itmeinp {
                        width: 100%;
                        height: 50px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;
                        margin-top: 30px;
                        input {
                            width: 1250px;
                            height: 50px;
                            background: #FFFFFF;
                            border: 1px solid #DFDFDF;
                            border-radius: 5px;
                            outline: none;
                            font-size: 14px;
                            box-sizing: border-box;
                            padding-left: 20px;
                        }
                    }
                    .itemchooes {
                        width: 100%;
                        min-height: 50px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;
                        margin-top: 30px;
                        .spanbox {
                            width: 1250px;
                            height: 50px;
                            background: #FFFFFF;
                            border: 1px solid #DFDFDF;
                            border-radius: 5px;
                            font-size: 14px;
                            box-sizing: border-box;
                            padding-left: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            color: #757575;
                            .span2 {
                                width: 114px;
                                height: 50px;
                                background: #DCDCDC;
                                border-radius: 5px;
                                text-align: center;
                                line-height: 50px;
                            }
                        }
                    }
                }
                .apply {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    box-sizing: border-box;
                    padding-right: 30px;
                    margin-bottom: 30px;
                    span {
                        display: inline-block;
                        width: 165px;
                        height: 50px;
                        background: #0277BD;
                        border-radius: 5px;
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 50px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .phone {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            .titlex {
                width: 100%;
                height: 80px;
                font-size: 28px;
                font-weight: 600;
                text-align: left;
                line-height: 80px; 
            }
            .formbox {
                width: 100%;
                background: #FFFFFF;
                border: 2px solid #DFDFDF;
                border-radius: 4px;
                margin-bottom: 30px;
                .formtitle {
                    width: 100%;
                    height: 60px;
                    font-size: 28px;
                    text-align: left;
                    line-height: 60px;
                    border-bottom: 2px solid #DFDFDF;
                    box-sizing: border-box;
                    padding: 0 30px;
                }
                .itemsbox {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 30px;
                    .itmeinp {
                        width: 100%;
                        min-height: 120px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        font-size: 24px;
                        margin-top: 30px;
                        input {
                            width: 100%;
                            height: 80px;
                            background: #FFFFFF;
                            border: 3px solid #DFDFDF;
                            border-radius: 5px;
                            outline: none;
                            font-size: 24px;
                            box-sizing: border-box;
                            padding-left: 20px;
                            margin-top: 10px;
                        }
                    }
                    .itemchooes {
                        width: 100%;
                        min-height: 120px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        font-size: 24px;
                        margin-top: 30px;
                        .upload-demo {
                            width: 100%;
                            margin-top: 10px;
                            :deep(.el-upload) {
                                width: 100%;
                                height: 100%;
                                .spanbox {
                                    width: 100%;
                                    height: 80px;
                                    background: #FFFFFF;
                                    border: 3px solid #DFDFDF;
                                    border-radius: 5px;
                                    font-size: 24px;
                                    box-sizing: border-box;
                                    padding-left: 20px;
                                    color: #757575;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    .span1 {
                                        width: 600px;
                                        text-align: left;
                                    }
                                    .span2 {
                                        width: 120px;
                                        height: 80px;
                                        line-height: 80px;
                                        background: #DCDCDC;
                                        border-radius: 5px;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
                .apply {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    box-sizing: border-box;
                    padding-right: 30px;
                    margin-bottom: 30px;
                    span {
                        display: inline-block;
                        width: 200px;
                        height: 60px;
                        background: #0277BD;
                        border-radius: 5px;
                        font-size: 24px;
                        font-family: Arial;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 60px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>