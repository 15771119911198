<template>
    <!-- 贷款详情 -->
    <div class="pc">
        <div class="bigbox">
            <div class="allbox">
                <div class="loanxq">
                    <span>{{ $t('seller.loanDetails.loanDetails') }}</span>
                </div>
                <div class="centbox">
                    <div class="item">
                        <span>{{ $t('seller.loanDetails.loanDate') }}</span>
                        <span>{{ details.created_at }}</span>
                    </div>
                    <div class="item">
                        <span>{{ $t('seller.loanDetails.totalBorrowingAmount') }}</span>
                        <span>{{ details.loan_amount }}</span>
                    </div>
                    <div class="item">
                        <span>{{ $t('seller.loanDetails.outstandingMoney') }}</span>
                        <span>{{ details.outstanding_money }}</span>
                    </div>
                    <div class="item">
                        <span>{{ $t('seller.loanDetails.monthRate') }}</span>
                        <span>{{ details.monthly_interest_rate }}</span>
                    </div>
                    <div class="item">
                        <span>{{ $t('seller.loanDetails.totalInterest') }}</span>
                        <span>{{ details.total_interest }}</span>
                    </div>
                </div>
                <div class="tabsbox">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column type="index" label="#"/>
                        <el-table-column prop="months_time" :label="$t('seller.loanDetails.month')" width="220" />
                        <el-table-column prop="loan_amount" :label="$t('seller.loanDetails.borrowingMoney')" width="220" />
                        <el-table-column prop="interest" :label="$t('seller.loanDetails.interestMoney')" width="220" />
                        <el-table-column prop="total_money" :label="$t('seller.loanDetails.totalMoney')" width="220" />
                        <el-table-column prop="status" :label="$t('seller.loanDetails.repaymentStatus')" width="220">
                            <template #default="scope">
                                <span v-if="scope.row.repayment_button == 2">{{ scope.row.status }}</span>
                                <span class="spans" v-else @click="repaymentChange(scope.row.id)">{{ $t('seller.loanDetails.repayment') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" :label="$t('seller.loanDetails.repaymentDate')" width="220" />
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background
                    layout="prev, pager, next"
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
            <div class="tanchuang" v-if="tcshow">
                <div class="neirbox">
                    <div class="titles">
                        <span>{{ $t('seller.confirmPay.confirmPay') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="tcshow = false">
                    </div>
                    <div class="cents">
                        <span>{{ $t('seller.confirmPay.doYouReallyWantToPay') }}?</span>
                    </div>
                    <div class="btnbox">
                        <span @click="tcshow = false">{{ $t('seller.confirmPay.cancel') }}</span>
                        <span @click="confirmRepay">{{ $t('seller.confirmPay.pay') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class="bigbox">
            <div class="allbox">
                <div class="loanxq">
                    <span>{{ $t('seller.loanDetails.loanDetails') }}</span>
                </div>
                <div class="centbox">
                    <div class="item">
                        <span>{{ $t('seller.loanDetails.loanDate') }}</span>
                        <span>{{ details.created_at }}</span>
                    </div>
                    <div class="item">
                        <span>{{ $t('seller.loanDetails.totalBorrowingAmount') }}</span>
                        <span>{{ details.loan_amount }}</span>
                    </div>
                    <div class="item">
                        <span>{{ $t('seller.loanDetails.outstandingMoney') }}</span>
                        <span>{{ details.outstanding_money }}</span>
                    </div>
                    <div class="item">
                        <span>{{ $t('seller.loanDetails.monthRate') }}</span>
                        <span>{{ details.monthly_interest_rate }}</span>
                    </div>
                    <div class="item">
                        <span>{{ $t('seller.loanDetails.totalInterest') }}</span>
                        <span>{{ details.total_interest }}</span>
                    </div>
                </div>
                <div class="tabsbox">
                    <el-table :data="tableData" border style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column type="index" label="#"/>
                        <el-table-column prop="months_time" :label="$t('seller.loanDetails.month')" width="220" />
                        <el-table-column prop="loan_amount" :label="$t('seller.loanDetails.borrowingMoney')" width="220" />
                        <el-table-column prop="interest" :label="$t('seller.loanDetails.interestMoney')" width="220" />
                        <el-table-column prop="total_money" :label="$t('seller.loanDetails.totalMoney')" width="220" />
                        <el-table-column prop="status" :label="$t('seller.loanDetails.repaymentStatus')" width="220">
                            <template #default="scope">
                                <span v-if="scope.row.repayment_button == 2">{{ scope.row.status }}</span>
                                <span class="spans" v-else @click="repaymentChange(scope.row.id)">{{ $t('seller.loanDetails.repayment') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" :label="$t('seller.loanDetails.repaymentDate')" width="220" />
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background
                    class="my-pagination"
                    layout="prev, pager, next"
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
            <div class="tanchuang" v-if="tcshow">
                <div class="neirbox">
                    <div class="titles">
                        <span>{{ $t('seller.confirmPay.confirmPay') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="tcshow = false">
                    </div>
                    <div class="cents">
                        <span>{{ $t('seller.confirmPay.doYouReallyWantToPay') }}?</span>
                    </div>
                    <div class="btnbox">
                        <span @click="tcshow = false">{{ $t('seller.confirmPay.cancel') }}</span>
                        <span @click="confirmRepay">{{ $t('seller.confirmPay.pay') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {loanDetails,loanRepaymentBill,loanRepayment} from '@/api/sellerapi'
    import { ElMessage } from 'element-plus'
    export default {
        data () {
            return {
                id: '',
                ids: '',
                details: {}, //详情
                tableData: [],
                page: 1,
                count: 0,
                tcshow: false
            }
        },
        mounted(){
            this.id = this.$route.query.id
            this.getDetails()
            this.getHuankan()
        },
        methods:{
            // 获取详情
            async getDetails(){
                const {data: res} = await loanDetails({
                    id: this.id
                })
                if(res.status == 200) {
                    this.details = res.success
                    console.log(res)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 获取还款记录
            async getHuankan(){
                const {data: res} = await loanRepaymentBill({
                    id: this.id
                })
                if(res.status == 200) {
                    this.tableData = res.success.list
                    this.page = res.success.page
                    this.count = parseInt(res.success.count)
                    console.log(res)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 还款弹窗
            repaymentChange(ids){
                this.tcshow = true
                this.ids = ids
            },
            // 确认还款
            async confirmRepay(){
                this.tcshow = false
                const {data: res} = await loanRepayment({
                    id: this.ids
                })
                if(res.status == 200) {
                    this.getHuankan()
                    ElMessage.success(res.message)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 上一页
            prevChange(e){
				this.page = e
                this.getHuankan()
			},
			// 下一页
			nextChange(e){
				this.page = e
                this.getHuankan()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
                this.getHuankan()
			}
        }
    }
</script>
<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}

    .pc {
        .bigbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .allbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                margin-top: 30px;
                .loanxq {
                    width: 100%;
                    height: 60px;
                    font-size: 18px;
                    font-weight: 600;
                    text-align: left;
                    line-height: 60px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: 1px solid #DFDFDF;
                }
                .centbox {
                    width: 100%;
                    height: 170px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #DFDFDF;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    padding: 0 30px;
                    .item {
                        height: 30px;
                        line-height: 30px;
                        font-size: 14px;
                        span {
                            display: inline-block;
                            width: 260px;
                            text-align: left;
                        }
                    }
                }
                .tabsbox {
                    width: 96%;
                    margin-left: 30px;
                    text-align: left;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    .spans {
                        display: inline-block;
                        background: #1378BB;
                        border-radius: 5px;
                        padding: 5px 20px;
                        color: #FFFFFF;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            .tanchuang {
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                position: fixed;
                z-index: 99;
                top: 0;
                left: 0;
                overflow: hidden;
            .neirbox {
                    width: 30%;
                    background: #FFFFFF;
                    border-radius: 5px;
                    margin: auto;
                    margin-top: 20vh;
                    .titles {
                        width: 100%;
                        height: 80px;
                        border-bottom: solid 1px #e6e6e6;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 20px;
                        font-size: 20px;
                        font-weight: 600;
                        img {
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                        }
                    }
                    .cents {
                        height: 140px;
                        line-height: 140px;
                        text-align: left;
                        box-sizing: border-box;
                        padding-left: 20px;
                        font-size: 18px;
                    }
                    .btnbox {
                        width: 100%;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        box-sizing: border-box;
                        padding: 0 20px;
                        border-top: solid 1px #e6e6e6;
                        span {
                            display: inline-block;
                            width: 150px;
                            height: 50px;
                            border-radius: 5px;
                            line-height: 50px;
                            cursor: pointer;
                        }
                        span:nth-child(1){
                            background: #DFDFDF;
                        }
                        span:nth-child(2){
                            background: #0277BD;
                            color: #FFFFFF;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
    .phone {
        .bigbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .allbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                margin-top: 30px;
                .loanxq {
                    width: 100%;
                    height: 80px;
                    font-size: 28px;
                    font-weight: 600;
                    text-align: left;
                    line-height: 80px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: 1px solid #DFDFDF;
                }
                .centbox {
                    width: 100%;
                    box-sizing: border-box;
                    border-bottom: 1px solid #DFDFDF;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 30px;
                    .item {
                        line-height: 40px;
                        font-size: 24px;
                        display: flex;
                        justify-content: space-between;
                        span {
                            display: inline-block;
                            width: 48%;
                            text-align: left;
                        }
                    }
                }
                .tabsbox {
                    width: 96%;
                    margin-left: 30px;
                    text-align: left;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    .spans {
                        display: inline-block;
                        background: #1378BB;
                        border-radius: 5px;
                        padding: 10px 20px;
                        color: #FFFFFF;
                        font-size: 24px;
                        cursor: pointer;
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 50px;
                        height: 50px;
                    }
                    :deep(.number) {
                        font-size: 40px !important;
                    }
                    :deep(.is-first) {
                        width: 50px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 40px !important;
                    }
                }
            }
            .tanchuang {
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                position: fixed;
                z-index: 99;
                top: 0;
                left: 0;
                overflow: hidden;
                .neirbox {
                    width: 60%;
                    background: #FFFFFF;
                    border-radius: 5px;
                    margin: auto;
                    margin-top: 20vh;
                    .titles {
                        width: 100%;
                        height: 80px;
                        border-bottom: solid 1px #e6e6e6;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 20px;
                        font-size: 30px;
                        font-weight: 600;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .cents {
                        height: 140px;
                        line-height: 140px;
                        text-align: left;
                        box-sizing: border-box;
                        padding-left: 20px;
                        font-size: 28px;
                    }
                    .btnbox {
                        width: 100%;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        box-sizing: border-box;
                        padding: 0 20px;
                        border-top: solid 1px #e6e6e6;
                        span {
                            display: inline-block;
                            width: 150px;
                            height: 60px;
                            border-radius: 5px;
                            line-height: 60px;
                            font-size: 24px;
                        }
                        span:nth-child(1){
                            background: #DFDFDF;
                        }
                        span:nth-child(2){
                            background: #0277BD;
                            color: #FFFFFF;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
</style>