<template>
    <div class="allbox">
        <div class="titles">
            <span>All uploaded files</span>
            <span @click="toUploadNewFile">Upload New File</span>
        </div>
        <div class="allfiles">
            <div class="filetitle">
                <div class="leftwz">
                    <span>All files</span>
                </div>
                <div class="rightbox">
                    <el-select v-model="value" class="m-2" placeholder="Select" size="large">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                    <input type="text" placeholder="Search your files">
                    <span class="span1">Search</span>
                </div>
            </div>
            <div class="imgbigbox">
                <div class="imgbox" v-for="(item,index) in 10" :key="index">
                    <!-- <img class="gdimg" src="../../../assets/img/shouji.png"> -->
                    <span class="diandian" @click="tcshow = !tcshow">...</span>
                    <span>工单支持.png</span>
                    <span class="colorhui">170KB</span>
                    <div class="tcbox" v-if="tcshow">   
                        <div class="itemtc">
                            <img src="../../../assets/img/seller-details-hui.png">
                            <span>Details Info</span>
                        </div>
                        <div class="itemtc">
                            <img src="../../../assets/img/seller-xiazhai-hui.png">
                            <span>Download</span>
                        </div>
                        <div class="itemtc">
                            <img src="../../../assets/img/seller-copy-hui.png">
                            <span>Copy Link</span>
                        </div>
                        <div class="itemtc">
                            <img src="../../../assets/img/seller-shanchu-hui.png">
                            <span>Delete</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                tcshow: false, //点击图片弹窗显示
                options: [
                    {
                        value: 'Option1',
                        label: 'Option1',
                    },
                    {
                        value: 'Option2',
                        label: 'Option2',
                    },
                    {
                        value: 'Option3',
                        label: 'Option3',
                    },
                    {
                        value: 'Option4',
                        label: 'Option4',
                    },
                    {
                        value: 'Option5',
                        label: 'Option5',
                    }, 
                ]
            }
        },
        methods: {
            toUploadNewFile(){
                this.$router.push('/Home/SellerUploadNewFile')
            }
        }
    }
</script>

<style lang="less" scoped>
    .allbox {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0 25px;
        .titles {
            width: 100%;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            font-family: Arial;
            font-weight: 600;
            span:nth-child(2){
                width: 165px;
                height: 36px;
                background: #0277BD;
                border-radius: 4px;
                font-size: 14px;
                font-family: Arial;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 36px;
                cursor: pointer;
            }
        }
        .allfiles {
            width: 100%;
            min-height: 330px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 4px; 
            .filetitle {
                width: 100%;
                height: 55px;
                border-bottom: 1px solid #DFDFDF;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 0 30px;
                font-size: 14px;
                .leftwz {
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #1A1A1A;
                }
                :deep(.el-select) {
                    width: 196px;
                    height: 40px;
                }
                input {
                    width: 196px;
                    height: 36px;
                    outline: none;
                    border: 1px solid #DFDFDF;
                    border-radius: 4px;
                    font-size: 14px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    margin: 0 10px;
                }
                .span1 {
                    display: inline-block;
                    width: 80px;
                    height: 36px;
                    background: #0277BD;
                    border-radius: 4px;
                    color: #fff;
                    text-align: center;
                    line-height: 36px;
                    cursor: pointer;
                }
            }
            .imgbigbox {
                width: 100%;
                box-sizing: border-box;
                padding: 0 30px 30px 30px;
                display: flex;
                flex-wrap: wrap;
                .imgbox:nth-of-type(6n + 0) {
                    margin-right: 0px;
                } 
                .imgbox {
                    width: 240px;
                    min-height: 220px;
                    background: #FFFFFF;
                    border: 1px solid #E9E9E9;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 10px;
                    margin-top: 25px;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    position: relative;
                    margin-right: 14px;
                    .gdimg {
                        width: 220px;
                        height: auto;
                        margin-bottom: 15px;
                    }
                    .diandian {
                        writing-mode: tb-rl;
                        font-size: 28px;
                        font-weight: bold;
                        width: 20px;
                        text-align: center;
                        line-height: 5px;
                        cursor: pointer;
                        position: absolute;
                        right: 5px;
                        top: 5px;
                        z-index: 99999;
                    }
                    .tcbox {
                        width: 200px;
                        background: #FFFFFF;
                        border: 1px solid #E5E5E5;
                        border-radius: 4px;
                        position: absolute;
                        right: 20px;
                        top: 30px;
                        z-index: 1;
                        .itemtc {
                            width: 100%;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            color: #ABABAB;
                            cursor: pointer;
                            box-sizing: border-box;
                            padding-left: 10px;
                            img {
                               width: 20px;
                               height: 20px;
                               margin-right: 10px;
                            }
                        }
                        .itemtc :hover {
                            color: #00ADF1 !important;
                        }
                    }
                    span {
                        font-size: 16px;
                    }
                    .colorhui{
                        color: #969696;
                    }
                }
            }
        }
    }
</style>