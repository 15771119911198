<template>
    <div class="allbox">
        <div class="topname">
            <span>仨的阿瑟 #2147483647</span>
            <div class="yhm">
                <span>xxlBuyer 2023-06-30 17:19:29</span>
                <span>Pending</span>
            </div>
        </div>
        <div class="areabox">
            <textarea name="" id="" cols="30" rows="10"></textarea>
        </div>
        <div class="updatebox">
            <el-upload
                v-model:file-list="fileList"
                class="upload-demo"
                action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                multiple
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :limit="3"
                :on-exceed="handleExceed"
            >
                <div class="spanbox">
                    <span>Browse </span>
                    <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                </div>
            </el-upload>
        </div>
        <div class="sendbox">
            <span>Send Reply</span>
        </div>
        <div class="userbox" v-for="(item,index) in 2" :key="index">
            <div class="tops">
                <div class="topshead">
                    <img src="../../assets/img/touxiang.png">
                </div>
                <div class="yhmbox">
                    <span>仨的阿瑟 #2147483647</span>
                    <span>xxlBuyer 2023-06-30 17:19:29</span>
                </div>
            </div>
            <div class="bomtext">
                <span>Hello, this is after-sales service. Please contact online custo</span>
            </div>
        </div>
    </div>
</template>





<style lang="less" scoped>
    .allbox {
        width: 1080px;
        background: #fff;
        box-sizing: border-box;
        padding: 0 30px 40px 30px;
        .topname {
           width: 100%;
           height: 110px;
           border-bottom: solid 2px #F6F6F6; 
           text-align: left;
           display: flex;
           flex-direction: column;
           justify-content: center;
           &>span {
                font-size: 24px;
                font-weight: bold;
           }
           .yhm {
                display: flex;
                align-items: center;
                margin-top: 15px;
                span:nth-child(1){
                    font-size: 18px;
                    color: #969696;
                }
                span:nth-child(2){
                    display: inline-block;
                    padding: 0 10px;
                    height: 18px;
                    background: #969696;
                    border-radius: 4px;
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-left: 10px;
                }
           }
        }
        .areabox {
            width: 100%;
            height: 280px;
            margin-top: 25px;
            textarea {
                width: 100%;
                height: 100%;
                resize: none;
                border: solid 1px #e6e6e6;
                border-radius: 5px;
                outline: none;
                box-sizing: border-box;
                padding: 15px;
            }
        }
        .updatebox {
            width: 100%;
            height: 50px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin-top: 25px;
            .spanbox {
                width: 1020px;
                display: flex;
                cursor: pointer;
                span {
                    display: inline-block;
                    text-align: center;
                    line-height: 50px;
                }
                span:nth-child(1){
                    width: 110px;
                    height: 50px;
                    background: #969696;
                    border-radius: 3px;
                    color: #fff;
                }
                span:nth-child(2){
                    width: 100%;
                    text-align: left;
                    background: #F6F6F6;
                    box-sizing: border-box;
                    padding-left: 20px;
                }
            }
        }
        .sendbox {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            span {
                width: 245px;
                height: 50px;
                background: #00ADF1;
                border-radius: 4px;
                font-size: 18px;
                font-family: Arial;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 50px;
                cursor: pointer;
            }
        }
        .userbox {
            width: 100%;
            height: 200px;
            border-bottom: solid 1px #e6e6e6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .tops {
                display: flex;
                align-items: center;
                .topshead {
                    width: 66px;
                    height: 66px;
                    overflow: hidden;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .yhmbox {
                    margin-left: 30px;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    span:nth-child(1){
                        font-size: 24px;
                        font-weight: bold;
                    }
                    span:nth-child(2){
                        font-size: 18px;
                        color: #969696;
                        margin-top: 5px;
                    }
                }
            }
            .bomtext {
                margin-top: 10px;
                font-size: 14px;
                font-family: Arial;
                font-weight: 400;
                color: #969696;
                line-height: 36px;
                text-align: left;
            }
        }
    }
</style>

