<template>
 <!-- 贷款申请记录 -->
    <div class="pc">
        <div class='bodys'>
            <div class="bigbox">
                <div class="titles">
                    <span>{{ $t('seller.loanRequestHistories.loanRequestHistories') }}</span>
                </div>
                <div class="tabbox">
                    <el-table :data="tableData" style="width: 100%">
                        <el-table-column type="index" label="#" width="100" />
                        <el-table-column prop="loan_amount" :label="$t('seller.loanRequestHistories.loanAmount')" width="280" />
                        <el-table-column prop="monthly_interest_rate" :label="$t('seller.loanRequestHistories.monthRate')" width="280" />
                        <el-table-column prop="months" :label="$t('seller.loanRequestHistories.months')" width="280" />
                        <el-table-column prop="status" :label="$t('seller.loanRequestHistories.approval')" width="280">
                            <template #default="scope">
                                <span>{{ scope.row.status }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" :label="$t('seller.loanRequestHistories.date')" />
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background
                    layout="prev, pager, next"
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
        </div>
    </div>
    <div class="phone">
        <div class='bodys'>
            <div class="bigbox">
                <div class="titles">
                    <span>{{ $t('seller.loanRequestHistories.loanRequestHistories') }}</span>
                </div>
                <div class="tabbox">
                    <el-table :data="tableData" style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column type="index" label="#"/>
                        <el-table-column prop="loan_amount" :label="$t('seller.loanRequestHistories.loanAmount')"/>
                        <el-table-column prop="monthly_interest_rate" :label="$t('seller.loanRequestHistories.monthRate')"/>
                        <el-table-column prop="months" :label="$t('seller.loanRequestHistories.months')"/>
                        <el-table-column prop="status" :label="$t('seller.loanRequestHistories.approval')">
                            <template #default="scope">
                                <span>{{ scope.row.status }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" :label="$t('seller.loanRequestHistories.date')" />
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background
                    class="my-pagination"
                    layout="prev, pager, next"
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import {loanApplyBill} from '@/api/sellerapi'
    import { ElMessage } from 'element-plus'
    export default {
        data () {
            return {
                page: 1,
                count: 0,
                tableData: []
            }
        },
        mounted(){
            this.getDaikRequest()
        },
        methods:{
            // 获取贷款申请记录
            async getDaikRequest(){
                const {data: res} = await loanApplyBill({
                    page: this.page
                })
                if(res.status == 200) {
                    this.tableData = res.success.list
                    this.page = res.success.page
                    this.count = parseInt(res.success.count)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 上一页
            prevChange(e){
				this.page = e
                this.getDaikRequest()
			},
			// 下一页
			nextChange(e){
				this.page = e
                this.getDaikRequest()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
                this.getDaikRequest()
			}
        }
    }
</script>
<style lang="less" scoped>

    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}
    .pc {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .bigbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                margin-top: 30px;
                .titles {
                    width: 100%;
                    height: 55px;
                    line-height: 55px;
                    border-bottom: 1px solid #DFDFDF;
                    font-size: 18px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 30px;
                }
                .tabbox {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 30px;
                    span {
                        display: inline-block;
                        background: #22AC38;
                        border-radius: 4px;
                        font-size: 14px;
                        color: #FFFFFF;
                        padding: 0 10px;
                        margin:5px 0px;
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
    .phone {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            .bigbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                margin-top: 30px;
                .titles {
                    width: 100%;
                    height: 80px;
                    line-height: 80px;
                    border-bottom: 2px solid #DFDFDF;
                    font-size: 28px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 30px;
                }
                .tabbox {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 5px 30px;
                    span {
                        display: inline-block;
                        background: #22AC38;
                        border-radius: 5px;
                        font-size: 24px;
                        color: #FFFFFF;
                        padding: 5px 10px;
                        margin:5px 0px;
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 50px;
                        height: 50px;
                    }
                    :deep(.number) {
                        font-size: 40px !important;
                    }
                    :deep(.is-first) {
                        width: 50px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 40px !important;
                    }
                }
            }
        }
    }
</style>