<template>
    <div class="bigbox">
        <div class="title">
            <span>Support Ticket</span>
        </div>
        <div class="cjticket" @click="show = true">
            <span>+</span>
            <span>Create a Ticket</span>
            <span>+</span>
        </div>
        <div class="tickets">
            <div class="ticbox">
                <span>Tickets</span>
            </div>
            <div class="tablebox">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="ticketid" label="Ticket ID " width="180" />
                    <el-table-column prop="sendingdate" label="Sending Date " width="240" />
                    <el-table-column prop="subject" label="Subject" width="200"/>
                    <el-table-column prop="status" label="Status">
                        <template #default="scope">
                            <span class="span1">{{ scope.row.status }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="options" :label="$t('seller.myLoan.options')">
                        <template #default="scope">
                            <span class="span2" @click="toTicketDetail(scope.row)">{{ scope.row.options }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="fenye">
                <el-pagination background layout="prev, pager, next" :total="1000" />
            </div>
        </div>
        <!-- 上传弹窗 -->
        <div class="tcbox" v-if="show">
            <div class="bsbgbox">
                <div class="createbox">
                    <span>Create a Ticket</span>
                    <img src="../../assets/img/cuowu-hui.png" @click="show = false" >
                </div>
                <div class="subjectbox">
                    <span>Subject</span>
                    <input type="text" placeholder="Subject">
                </div>
                <div class="reply">
                    <span>Provide a detailed description</span>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
                <div class="flieup">
                    <span>Photo</span>
                    <el-upload
                        ref="upload"
                        v-model:file-list="fileList"
                        class="upload-demo"
                        action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                        multiple
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :limit="3"
                        :on-exceed="handleExceed"
                        :auto-upload="false"
                    >
                        <template #trigger>
                            <div class="browse">
                                <span>Browse</span>
                                <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                            </div>
                        </template>
                    </el-upload>
                </div>
                <div class="btnbox">
                    <span>Cancel</span>
                    <span>Send Ticket</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                show: false, //弹窗显示
                tableData: [
                    { ticketid: '#2147483647',sendingdate: '2023-06-30 16:56:09',subject: '仨的阿瑟',status: 'Solved',options: 'View Details'},
                    { ticketid: '#2147483647',sendingdate: '2023-06-30 16:56:09',subject: '仨的阿瑟',status: 'Solved',options: 'View Details'},
                    { ticketid: '#2147483647',sendingdate: '2023-06-30 16:56:09',subject: '仨的阿瑟',status: 'Solved',options: 'View Details'},
                    { ticketid: '#2147483647',sendingdate: '2023-06-30 16:56:09',subject: '仨的阿瑟',status: 'Solved',options: 'View Details'},
                    { ticketid: '#2147483647',sendingdate: '2023-06-30 16:56:09',subject: '仨的阿瑟',status: 'Solved',options: 'View Details'},
                ]
            }
        },
        methods: {
            // 去详情页查看
            toTicketDetail(e){
                this.$router.push('/index/mypage/supportticketdetail')
            }
        }
    }
</script>

<style scoped lang="less">
    .bigbox {
        width: 1080px;
        .title {
            font-size: 18px;
            font-family: Arial;
            font-weight: bold;
            text-align: left;
        }
        .cjticket {
            width: 100%;
            height: 160px;
            font-size: 36px;
            font-family: Arial;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
            line-height: 160px;
            background-image: url('../../assets/img/ticketbg.png');
            background-size: cover;
            margin-top: 10px;
            cursor: pointer;
            span:nth-child(2){
                margin: 0 30px;
            }
        }
        .tickets {
            width: 100%;
            box-sizing: border-box;
            padding: 0 30px;
            background: #FFFFFF;
            border-radius: 5px;
            margin-top: 35px;
            .ticbox {
                width: 100%;
                height: 60px;
                border-bottom: 1px solid #CACACA;
                text-align: left;
                line-height: 60px;
                font-size: 18px;
                font-family: Arial;
                font-weight: bold;
            }
            .tablebox {
                width: 100%;
                .span1 {
                    display: inline-block;
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    padding: 0 15px;
                    height: 25px;
                    background: #0ABB75;
                    border-radius: 5px;
                }
                .span2 {
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #00ADF1;
                    cursor: pointer;
                }
            }
        }
        .fenye {
            width: 100%;
            height: 70px;
            display: flex;
            justify-content: flex-end;
        }
        .tcbox {
            width: 100%;
            height: 100%;
            position: fixed;
            z-index: 999999;
            background: rgba(0, 0, 0, 0.6);
            top: 0;
            left: 0;
            .bsbgbox {
                width: 650px;
                height: 550px;
                overflow: auto;
                background: #FFFFFF;
                border-radius: 7px;
                margin: auto;
                margin-top: 100px;
                .createbox {
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: bold;
                    width: 100%;
                    height: 70px;
                    border-bottom: solid 1px #EDEDED;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0 30px;
                    img {
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                    }
                }
                .subjectbox {
                    font-size: 18px;
                    font-family: Arial;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: left;
                    margin-top: 25px;
                    input {
                        width: 470px;
                        height: 60px;
                        border: 1px solid #EDEDED;
                        border-radius: 4px;
                        outline: none;
                        font-size: 18px;
                        box-sizing: border-box;
                        padding-left: 10px;
                    }
                }
                .reply {
                    font-size: 18px;
                    font-family: Arial;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: left;
                    margin-top: 25px;
                    textarea {
                        width: 470px;
                        height: 150px;
                        border: 1px solid #EDEDED;
                        border-radius: 4px;
                        outline: none;
                        font-size: 18px;
                        box-sizing: border-box;
                        padding: 10px;
                        resize:none
                    }
                    span {
                        display: inline-block;
                        width: 80px;
                    }
                }
                .flieup {
                    font-size: 18px;
                    font-family: Arial;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: left;
                    margin-top: 25px;
                    .browse {
                        width: 470px;
                        height: 60px;
                        border: 1px solid #EDEDED;
                        border-radius: 4px;
                        font-size: 18px;
                        box-sizing: border-box;
                        line-height: 60px;
                        display: flex;
                        &>span:nth-child(1){
                            display: inline-block;
                            width: 100px;
                            height: 60px;
                            background: #E9E9E9;
                            border-radius: 4px;
                            text-align: center;
                            margin-right: 15px;
                        }
                    }
                }
                .btnbox {
                    width: 100%;
                    height: 100px;
                    border-top: solid 1px #EDEDED;
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    box-sizing: border-box;
                    padding-right: 30px;
                    span {
                        width: 138px;
                        height: 60px;
                        border-radius: 6px;
                        text-align: center;
                        line-height: 60px;
                        font-size: 18px;
                        margin-left: 15px;
                        cursor: pointer;
                    }
                    span:nth-child(1){
                        color: #00ADF1;
                        border: 1px solid #00ADF1;
                    }
                    span:nth-child(2){
                        color: #FFFFFF;
                        background: #00ADF1;
                    }
                }
            }
        }
    }
</style>