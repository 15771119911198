<template>
 <!-- 对话详情 -->
    <div class="pc">
        <div class='bodys'>
            <div class="title">
                <span>Conversations With</span>
                <span>{{ email }}</span>
            </div>
            <div class="bigbox">
                <div class="texts">
                    <span>{{ titles }}</span>
                </div>
                <div v-for="item in contenetList" :key="item.id">
                    <div class="suerbox">
                        <img :src="item.member_head">
                        <div class="uname">
                            <span>{{ item.email }}</span>
                            <span>{{ item.created_at }}</span>
                        </div>
                    </div>
                    <div class="msbox">
                    <span>{{ item.content }}</span>
                    </div>
                </div>
                <textarea v-model="content"></textarea>
                <div class="save">
                    <span @click="addContent">{{ $t('seller.shopSetting.save') }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class='bodys'>
            <div class="title">
                <span>Conversations With</span>
                <span>{{ email }}</span>
            </div>
            <div class="bigbox">
                <div class="texts">
                    <span>{{ titles }}</span>
                </div>
                <div class="itemuser" v-for="item in contenetList" :key="item.id">
                    <div class="suerbox">
                        <img :src="item.member_head">
                        <div class="uname">
                            <span>{{ item.email }}</span>
                            <span>{{ item.created_at }}</span>
                        </div>
                    </div>
                    <div class="msbox">
                    <span>{{ item.content }}</span>
                    </div>
                </div>
                <textarea v-model="content"></textarea>
                <div class="save">
                    <span @click="addContent">{{ $t('seller.shopSetting.save') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {converContent,contentAdd} from '@/api/sellerapi'
    import { ElMessage} from 'element-plus'
    export default {
        data () {
            return {
                id: '', //会话id
                contenetList: [], //对话列表
                email: '', //卖家
                titles: '',
                content: '', //会话内容
            }
        },
        mounted(){
            this.id = this.$route.query.id
            this.gethuiHuaContent()
        },
        methods:{
            // 获取会话内容
            async gethuiHuaContent(){
                const {data: res} = await converContent({
                    id: this.id
                })
                if(res.status == 200) {
                    this.contenetList = res.success.list
                    this.titles = res.success.title
                    this.email = res.success.sller_email
                    console.log(res)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 添加会话
            async addContent(){
                if(this.content == '') {return ElMessage.error('Please enter content')}
                const {data: res} = await contentAdd({
                    id: this.id,
                    content: this.content,
                })
                this.content = ''
                if(res.status == 200) {
                   ElMessage.success(res.message)
                   this.gethuiHuaContent()
                }else {
                    ElMessage.error(res.message)
                }
            }
        }
    }
</script>
<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}
    .pc {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .title {
                width: 100%;
                height: 60px;
                text-align: left;
                line-height: 60px;
                font-size: 18px;
                font-weight: 600;
                span:nth-child(2){
                    color: #4379bd;
                    margin-left: 10px;
                }
            }
            .bigbox {
                width: 100%;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 0 30px;
                .texts {
                    width: 100%;
                    height: 55px;
                    line-height: 55px;
                    font-size: 14px;
                    text-align: left;
                    font-weight: 600;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border-bottom: solid 1px #DFDFDF;
                }
                .suerbox {
                    width: 100%;
                    display: flex;
                    margin-top: 20px;
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                    .uname {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-left: 20px;
                        text-align: left;
                        span:nth-child(1) {
                            font-size: 22px;
                        }
                        span:nth-child(2){
                            font-size: 16px;
                            color: #969696;
                        }
                    }
                }
                .msbox {
                    text-align: left;
                    color: #969696;
                    font-size: 14px;
                    margin-top: 30px;
                    margin-left: 85px;
                }
                textarea {
                    width: 100%;
                    height: 200px;
                    border: solid 1px #DFDFDF;
                    outline: none;
                    resize: none;
                    box-sizing: border-box;
                    padding: 10px;
                    font-size: 16px;
                    margin-top: 30px;
                }
                .save {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    span {
                        width: 165px;
                        height: 50px;
                        background: #0277BD;
                        border-radius: 4px;
                        text-align: center;
                        line-height: 50px;
                        color: #fff;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .phone {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .title {
                width: 100%;
                height: 80px;
                text-align: left;
                line-height: 80px;
                font-size: 28px;
                font-weight: 600;
                span:nth-child(2){
                    color: #4379bd;
                    margin-left: 10px;
                }
            }
            .bigbox {
                width: 100%;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 0 30px;
                .texts {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    font-size: 28px;
                    text-align: left;
                    font-weight: 600;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border-bottom: solid 1px #DFDFDF;
                }
                .itemuser {
                    border: solid 2px #DFDFDF;
                    box-sizing: border-box;
                    padding-left: 10px;
                    padding-bottom: 20px;
                    margin-top: 10px;
                    .suerbox {
                        width: 100%;
                        display: flex;
                        margin-top: 20px;
                        img {
                            width: 90px;
                            height: 90px;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                        .uname {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            margin-left: 20px;
                            text-align: left;
                            span:nth-child(1) {
                                font-size: 32px;
                            }
                            span:nth-child(2){
                                font-size: 26px;
                                color: #969696;
                            }
                        }
                    }
                }
                
                .msbox {
                    text-align: left;
                    color: #969696;
                    font-size: 24px;
                    margin-top: 30px;
                    margin-left: 110px;
                }
                textarea {
                    width: 100%;
                    height: 200px;
                    border: solid 1px #DFDFDF;
                    outline: none;
                    resize: none;
                    box-sizing: border-box;
                    padding: 10px;
                    font-size: 26px;
                    margin-top: 30px;
                }
                .save {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    span {
                        width: 200px;
                        height: 60px;
                        background: #0277BD;
                        border-radius: 4px;
                        text-align: center;
                        line-height: 60px;
                        color: #fff;
                        font-size: 26px;
                    }
                }
            }
        }
    }
</style>