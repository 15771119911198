import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Index from '../views/index/Index.vue'
import Login from '../views/login/Login.vue'
import Signup from '../views/login/Signup.vue'
import SellerSignup from '../views/login/SellerSignup.vue'
import CentPage from '../views/buyer/CentPage.vue'
import MyPage from '../views/buyer/MyPage.vue'
import MyPagePhone from '../views/buyer/MyPagePhone.vue'
import Dashboard from '../views/buyer/Dashboard.vue'
import ManageProfile from '../views/buyer/ManageProfile.vue'
import PurchaseHistory from '../views/buyer/PurchaseHistory.vue'
import SentRefundRequest from "../views/buyer/SentRefundRequest.vue"
import OrderDetails from '../views/buyer/OrderDetails.vue'
import SendRefundRequest from '../views/buyer/SendRefundRequest.vue'
import ShopDetail from '../views/buyer/ShopDetail.vue'
import ShopDetailTwo from '../views/buyer/ShopDetailTwo.vue'
import Wishlist from '../views/buyer/Wishlist.vue'
import MyWallet from '../views/buyer/MyWallet.vue'
import Conversations from '../views/buyer/Conversations.vue'
import SupportTicket from '../views/buyer/SupportTicket.vue'
import BeaBrushingUser from '../views/buyer/BeaBrushingUser.vue'
import ConversationsWith from '../views/buyer/ConversationsWith.vue'
import SupportTicketDetail from '../views/buyer/SupportTicketDetail.vue'
import AllCategories from '../views/buyer/AllCategories.vue'
import CategorieyDetail from '../views/buyer/CategorieyDetail.vue'
import Cart from '../views/buyer/cart/Cart.vue'
import MyCart from '../views/buyer/cart/MyCart.vue'
import ShippingInfo from '../views/buyer/cart/ShippingInfo.vue'
import DeliveryInfo from  '../views/buyer/cart/DeliveryInfo.vue'
import Payment from '../views/buyer/cart/Payment.vue'
import CompleteOrder from '../views/buyer/cart/CompleteOrder.vue'
import ReturnsPolicy from '../views/buyer/ReturnsPolicy.vue'
import StoreHome from '../views/buyer/StoreHome.vue'
import FlashSale from '../views/buyer/FlashSale.vue'
import FlashSaleDetails from '../views/buyer/FlashSaleDetails.vue'
import Blogs from '../views/buyer/Blogs.vue'
import BlogsDetails from '../views/buyer/BlogsDetails.vue'
import Coupons from '../views/buyer/Coupons.vue'
import CouponsDetails from '../views/buyer/CouponsDetails.vue'
import Home from '../views/seller/SellerHome.vue'
import SellerDashboard from '../views/seller/Dashboard/SellerDashboard.vue'
import SellerProducts from '../views/seller/Products/SellerProducts.vue'
import SellerUploadFiles from '../views/seller//UploadFiles/SellerUploadFiles.vue'
import SellerUploadNewFile from '../views/seller/UploadFiles/SellerUploadNewFile.vue'
import SellerOrders from '../views/seller/Orders/SellerOrders.vue'
import SellerAddProduct from '../views/seller/Products/SellerAddProduct.vue'
import AddNewInHousePorducts from '../views/seller/Products/AddNewInHousePorducts.vue'
import SellerShopSetting from '../views/seller/ShopSetting/SellerShopSetting.vue'
import SellerMyWallet from '../views/seller/MyWallet/SellerMyWallet.vue'
import SellerManageProfile from '../views/seller/Dashboard/SellerManageProfile.vue'
import SellerSupportTicket from '../views/seller/SupportTicket/SellerSupportTicket.vue'
import ViewDetails from '../views/seller/SupportTicket/ViewDetails.vue'
import SellerMoneyWithdraw from '../views/seller/MoneyWithdraw/SellerMoneyWithdraw.vue'
import SellerMyLoan from '../views/seller/MyLoan/SellerMyLoan.vue'
import SellerLoanDetails from '../views/seller/MyLoan/SellerLoanDetails.vue'
import SellerLoanRequestForm from '../views/seller/MyLoan/SellerLoanRequestForm.vue'
import SellerRefundRequests from '../views/seller/RefundRequests/SellerRefundRequests.vue'
import SellerPaymentHistory from '../views/seller/PaymentHistory/SellerPaymentHistory.vue'
import SellerCommissionHistory from '../views/seller/CommissionHistory/SellerCommissionHistory.vue'
import SellerOrderDetails from '../views/seller/Orders/SellerOrderDetails.vue'
import SellerConversations from '../views/seller/Conversations/SellerConversations.vue'
import SellerConversationsWith from '../views/seller/Conversations/SellerConversationsWith.vue'
import LoanRequestHistories from '../views/seller/LoanRequestHistories/LoanRequestHistories.vue'
import LoanQuotaRequestHistories from '../views/seller/LoanQuotaRequestHistories/LoanQuotaRequestHistories.vue'



const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
    redirect: '/index/centpage',
    children: [
      {path: 'centpage', component: CentPage},// 首页中间部分
      {path: 'login', component: Login},// 登录
      {path: 'signup', component: Signup}, //注册
      {path: 'sellersignup', component: SellerSignup}, //注册
      {path: 'shopdetail', component: ShopDetail}, //商品详情卖家
      {path: 'shopdetailbuyer', component: ShopDetailTwo}, //商品详情买家
      {path: 'allcategories', name: 'allcategories', component: AllCategories}, //所有分类
      {path: 'flashsale', component: FlashSale}, //秒杀
      {path: 'flashsaledetail', component: FlashSaleDetails}, //秒杀详情
      {path: 'blogs', component: Blogs}, //博客
      {path: 'blogsdetail', component: BlogsDetails}, //博客详情
      {path: 'coupons', component: Coupons}, //优惠卷
      {path: 'couponsdetail', component: CouponsDetails}, //优惠卷详情
      {path: 'categorieydetail', component: CategorieyDetail}, //分类详情
      {path: 'returnspolicy', component: ReturnsPolicy}, //退货政策
      {path: 'storehome', component: StoreHome}, //卖家主页
      {
        path: 'cart', 
        name: 'cart',
        component: Cart,
        redirect: '/index/cart/mycart',
        children: [
          {path: 'mycart', component: MyCart}, //购物车第一步
          {path: 'shippinginfo', component: ShippingInfo}, //购物车第二步
          {path: 'deliveryinfo', component: DeliveryInfo}, //购物车第三步
          {path: 'payment', component: Payment}, //购物车第四步
          {path: 'completeorder', component: CompleteOrder}, //完成订单
        ]
      }, //购物车
      { //买家个人主页
        path: 'mypage',
        component: MyPage,
        redirect: '/index/mypage/dashboard',
        children: [
          {path: 'dashboard', component: Dashboard}, //仪表盘
          {path: 'manageprofile', component: ManageProfile}, //个人资料管理
          {path: 'purchasehistory', component: PurchaseHistory}, //购买历史
          {path: 'sentrefundrequest', component: SentRefundRequest}, //已发送退款请求
          {path: 'orderdetails', component: OrderDetails}, //购买历史详情
          {path: 'sendrefundrequest', component: SendRefundRequest}, //发送退款请求
          {path: 'wishlist', component: Wishlist}, //愿望清单
          {path: 'mywallet', component: MyWallet}, //我的钱包
          {path: 'conversations', component: Conversations}, //对话列表
          {path: 'conversationswith', component: ConversationsWith},//发送对话
          {path: 'supportticket', component: SupportTicket}, //工单
          {path: 'supportticketdetail', component: SupportTicketDetail}, //工单详情
          {path: 'beabrushinguser', component: BeaBrushingUser} //邀请
        ]
      },
      { //手机端才显示
        path: 'mypages',
        name: 'mypages',
        component: MyPagePhone,
      }
    ]
  },
  {
    path: '/Home',
    redirect: '/Home/SellerDashboard'
  },
  {
    path: '/Home',
    name: 'home',
    component: Home,
    children: [
      {path: 'SellerDashboard',component: SellerDashboard},
      {path: 'SellerProducts',component: SellerProducts},
      {path: 'SellerAddProduct',component: SellerAddProduct}, //添加产品
      {path: 'AddNewInHousePorducts',component: AddNewInHousePorducts}, //添加内部产品
      {path: 'SellerUploadFiles',component: SellerUploadFiles},
      {path: 'SellerUploadNewFile',component: SellerUploadNewFile},
      {path: 'SellerOrders',component: SellerOrders}, //订单
      {path: 'SellerOrderDetails',component: SellerOrderDetails}, //订单详情
      {path: 'SellerShopSetting',component: SellerShopSetting}, //店铺设置
      {path: 'SellerMyWallet',component: SellerMyWallet}, //我的钱包
      {path: 'SellerManageProfile',component: SellerManageProfile}, //管理配置文件
      {path: 'SellerSupportTicket',component: SellerSupportTicket}, //支票
      {path: 'ViewDetails',component: ViewDetails}, //支票详情
      {path: 'SellerMoneyWithdraw',component: SellerMoneyWithdraw}, //提现
      {path: 'SellerMyLoan',component: SellerMyLoan}, //贷款
      {path: 'SellerLoanDetails',component: SellerLoanDetails}, //贷款详情
      {path: 'SellerLoanRequestForm',component: SellerLoanRequestForm}, //贷款申请表单
      {path: 'SellerRefundRequests',component: SellerRefundRequests}, //收到的退款请求
      {path: 'SellerPaymentHistory',component: SellerPaymentHistory}, //支付历史记录
      {path: 'SellerCommissionHistory',component: SellerCommissionHistory}, //佣金历史记录
      {path: 'SellerConversations',component: SellerConversations}, //对话
      {path: 'SellerConversationsWith',component: SellerConversationsWith}, //对话详情
      {path: 'LoanRequestHistories',component: LoanRequestHistories}, //贷款申请历史记录
      {path: 'LoanQuotaRequestHistories',component: LoanQuotaRequestHistories}, //贷款配额请求历史记录
    ]
  },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 获取url参数
let url = window.location.href
let geturlinfo = url.split('?')[1]
if(geturlinfo) {
  let getqys = new URLSearchParams('?' + geturlinfo)
  let getToken = getqys.get('member_token')
  sessionStorage.setItem('member_token', getToken)
  sessionStorage.setItem('member_role', '2')
}

router.beforeEach((to,from,next) => {
    const token = sessionStorage.getItem('member_token')? true: false
    if(!token) {
      if(to.path == '/index/login' || to.path == '/index/centpage' || to.path == '/index/signup' || to.path == '/index/sellersignup' || to.path == '/index/categorieydetail' || to.path == '/index/allcategories' || to.path == '/index/shopdetailbuyer' || to.path == '/index/storehome' || to.path == '/index/returnspolicy' || to.path == '/index/flashsale' || to.path == '/index/blogs' || to.path == '/index/coupons') {
        next()
      }else {
        next('/index/login')
      }
    }else {
      next()
    }
})

export default router
