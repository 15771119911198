<template>
  <!-- 商品详情放大镜效果组件 -->
  <div class="goods-image">
    <div v-if="isShow" class="large" :style="[{ backgroundImage: `url(${images[currIndex]})` }, bgPosition]"></div>
    <ul class="small">
      <li v-for="(img, i) in images" :key="img" :class="{ active: i === currIndex }">
        <img @mouseenter="currIndex = i" :src="img" alt="" />
      </li>
    </ul>
    <div class="middle" ref="target">
      <img :src="images[currIndex]" alt="" />
      <div class="layer" v-if="isShow" :style="[position]"></div>
    </div>
  </div>
</template>
  <script>
import { ref, watch, reactive } from 'vue'
import { useMouseInElement } from '@vueuse/core'
export default {
  name: 'GoodsImage',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const currIndex = ref(0)
    const target = ref(null)
    const isShow = ref(false)
    const position = reactive({
      left: 0,
      top: 0,
    })
    const bgPosition = reactive({
      backgroundPositionX: 0,
      backgroundPositionY: 0,
    })
    const { elementX, elementY, isOutside } = useMouseInElement(target)
    watch([elementX, elementY, isOutside], () => {
      isShow.value = !isOutside.value
      if (isOutside.value) return
      if (elementX.value <= 100) {
        position.left = 0
      } else if (elementX.value >= 300) {
        position.left = 200
      } else {
        position.left = elementX.value - 100
      }
      if (elementY.value <= 100) {
        position.top = 0
      } else if (elementY.value >= 300) {
        position.top = 200
      } else {
        position.top = elementY.value - 100
      }
      bgPosition.backgroundPositionX = -position.left * 2 + 'px'
      bgPosition.backgroundPositionY = -position.top * 2 + 'px'
      position.left += 'px'
      position.top += 'px'
    })
    return { currIndex, target, isShow, position, bgPosition }
  },
}
</script>
  <style scoped lang="less">
.goods-image {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
  z-index: 99999;
  margin-left: 20px;
  .large {
    position: absolute;
    top: 0;
    left: 600px;
    width: 400px;
    height: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-size: 800px 800px;
    background-color: #f8f8f8;
  }
  .middle {
    width: 460px;
    height: auto;
    background: #fff;
    position: relative;
    cursor: move;
    .layer {
      width: 200px;
      height: 200px;
      background: rgba(0, 0, 0, 0.2);
      left: 0;
      top: 0;
      position: absolute;
    }
    & > img {
      object-fit: contain;
      width: 400px;
      height: 400px;
    }
  }

  //左侧小图
  .small::-webkit-scrollbar {
    display: none;
  }
  .small {
    width: 80px;
    height: 300px;
    overflow: auto;
    margin-top: 0;
    li {
      width: 51px;
      height: 51px;
      overflow: hidden;
      margin-left: -38px;
      margin-bottom: 15px;
      border-radius: 10px;
      border: none;
      img {
        width: 100%;
        height: 100%;
      }
      cursor: pointer;
      &:hover,
      &.active {
        border: 2px solid #04adf1;
      }
    }
  }
}
</style>
  