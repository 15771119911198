<template>
    <!-- 电脑端 -->
    <div class="pc">
        <div class="allbox">
            <div class="itembox" v-for="(item,index) in goodsList" :key="index">
                <div class="bullring">
                    <span>{{ item.shop_name }}</span>
                </div>
                <div class="itemshop" v-for="itemd in item.goods_list" :key="itemd.id">
                    <img :src="itemd.main_img">
                    <span>{{ itemd.goods_name }}</span>
                </div>
                <div class="botmbox">
                    <span>{{ $t('buyer.shoppingInfo.chooseDeliveryType') }}</span>
                    <div class="itemboxs">
                        <img src="../../../assets/img/xuanze.png">
                        <span>{{ $t('buyer.shoppingInfo.homeDelivery') }}</span>
                    </div>
                </div>
            </div>
            <div class="dibubox">
                <div class="leftfanhui" @click="fanhui">
                    <img src="../../../assets/img/zuojiantou-lan.png">
                    <span>{{ $t('buyer.myCart.returnToShop') }}</span>
                </div>
                <div class="rightbox" @click="toPayment">
                    <span>{{ $t('buyer.myCart.continueToPayment') }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class="allbox">
            <div class="itembox" v-for="(item,index) in goodsList" :key="index">
                <div class="bullring">
                    <span>{{ item.shop_name }}</span>
                </div>
                <div class="itemshop" v-for="itemd in item.goods_list" :key="itemd.id">
                    <img :src="itemd.main_img">
                    <span>{{ itemd.goods_name }}</span>
                </div>
                <div class="botmbox">
                    <span>{{ $t('buyer.shoppingInfo.chooseDeliveryType') }}</span>
                    <div class="itemboxs">
                        <img src="../../../assets/img/xuanze.png">
                        <span>{{ $t('buyer.shoppingInfo.homeDelivery') }}</span>
                    </div>
                </div>
            </div>
            <div class="dibubox">
                <div class="rightbox" @click="toPayment">
                    <span>{{ $t('buyer.myCart.continueToPayment') }}</span>
                </div>
                <div class="leftfanhui" @click="fanhui">
                    <img src="../../../assets/img/zuojiantou-lan.png">
                    <span>{{ $t('buyer.myCart.returnToShop') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {deliveryInfo} from '@/api/buyerapi'
    export default {
        data(){
            return {
                goodsList: [], //商品列表
            }
        },
        mounted(){
            this.getSonghuo()
        },
        methods: {
            // 获取送货信息
            async getSonghuo(){
                const {data: res} = await deliveryInfo()
                this.goodsList = res.success
                console.log(res)
            },
            // 下一步
            toPayment(){
                this.$router.push('/index/cart/Payment')
            },
            fanhui(){
                this.$router.push('/index')
            },
        }
    }
</script>


<style lang="less" scoped>

    // 横屏
    @media all and (orientation: landscape) {
        .pc {
            display: block !important;
        }
        .phone {
            display: none !important;
        }
    }

    /* 竖屏*/
    @media all and (orientation : portrait) {
        .pc {
            display: none !important;
        }
        .phone {
            display: block !important;
        }
    }

    .pc {
        .allbox {
            width: 1380px;
            .itembox {
                width: 100%;
                background: #fff;
                width: 100%;
                margin-top: 30px;
                .bullring {
                    width: 100%;
                    padding: 0 30px;
                    box-sizing: border-box;
                    height: 60px;
                    font-size: 14px;
                    text-align: left;
                    line-height: 70px;
                }
                .itemshop {
                    width: 100%;
                    height: 115px;
                    border-top: 1px solid #CACACA;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    line-height: 26px;
                    img {
                        width: 80px;
                        height: 80px;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    span {
                        display: inline-block;
                        width: 800px;
                        height: 40px;
                        text-align: left;
                    }
                }
                .botmbox {
                    height: 115px;
                    font-size: 14px;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 30px;
                    align-items: center;
                    box-sizing: border-box;
                    border-top: 1px solid #CACACA;
                    .itemboxs {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 250px;
                        height: 60px;
                        border: 1px solid #00ADF1;
                        border-radius: 5px;
                        cursor: pointer;
                        img {
                            width: 30px;
                            height: 30px;
                            margin-right: 10px;
                        }
                        font-size: 18px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #00ADF1;
                    }
                }
            }
            .dibubox {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 30px;
                margin-top: 30px;
                .leftfanhui {
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #00ADF1;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                .rightbox {
                    width: 190px;
                    height: 42px;
                    background: #00ADF1;
                    border-radius: 5px;
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 42px;
                    cursor: pointer;
                }
            }
        }
    }
    
    .phone {
        .allbox {
            width: 100%;
            .itembox {
                width: 100%;
                background: #fff;
                width: 100%;
                margin-top: 30px;
                .bullring {
                    width: 100%;
                    padding: 0 30px;
                    box-sizing: border-box;
                    height: 60px;
                    font-size:30px;
                    text-align: left;
                    line-height: 70px;
                }
                .itemshop {
                    width: 100%;
                    height: 115px;
                    border-top: 1px solid #CACACA;
                    display: flex;
                    align-items: center;
                    font-size: 24px;
                    line-height: 26px;
                    padding: 20px 0px;
                    img {
                        width: 100px;
                        height: 100px;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    span {
                        display: inline-block;
                        width: 800px;
                        height: 40px;
                        text-align: left;
                    }
                }
                .botmbox {
                    font-size: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 30px;
                    align-items: flex-start;
                    box-sizing: border-box;
                    border-top: 1px solid #CACACA;
                    &>span {
                        margin: 0px 0px 20px 0px;
                    }
                    .itemboxs {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 250px;
                        height: 60px;
                        border: 1px solid #00ADF1;
                        border-radius: 5px;
                        cursor: pointer;
                        img {
                            width: 30px;
                            height: 30px;
                            margin-right: 10px;
                        }
                        font-size: 28px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #00ADF1;
                    }
                }
            }
            .dibubox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 0 30px;
                margin-top: 30px;

                .leftfanhui {
                    font-size: 24px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #00ADF1;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-top: 20px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

                .rightbox {
                    width: 50%;
                    height: 80px;
                    background: #00ADF1;
                    border-radius: 5px;
                    font-size: 26px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 80px;
                }
            }
        }
    }
</style>