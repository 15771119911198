import request from "@/utils/request";

// 首页banner图
export const hBanner = () => {
    return request({
        url: "/v1/h/banner",
        method: 'POST',
    })
}

// 首页左侧分类
export const pCate = () => {
    return request({
        url: "/v1/p/cate",
        method: 'POST',
    })
}

// 轮播图下面商品分类
export const cateTj = () => {
    return request({
        url: "/v1/p/cate-tj",
        method: 'POST',
    })
}

// 轮播图右侧商品图
export const gHot = () => {
    return request({
        url: "/v1/h/g-hot",
        method: 'POST',
    })
}

// 新产品
export const newProduct = () => {
    return request({
        url: "/v1/h/g-new",
        method: 'POST',
    })
}

// 特色产品
export const TeseProduct = () => {
    return request({
        url: "/v1/h/g-ts",
        method: 'POST',
    })
}

// 3个广告图
export const threeAd = () => {
    return request({
        url: "/v1/h/ad",
        method: 'POST',
    })
}

// 排行10产品
export const topTen = () => {
    return request({
        url: "/v1/p/cate-top",
        method: 'POST',
    })
}

// 排行10产品
export const topBrand = () => {
    return request({
        url: "/v1/p/brand-top",
        method: 'POST',
    })
}

// 搜索
export const search = (data) => {
    return request({
        url: "/v1/search",
        method: 'POST',
        data
    })
}

// 店铺信息
export const shopInfo = (data) => {
    return request({
        url: "/v1/shop-info",
        method: 'POST',
        data
    })
}

// 店铺商品
export const shopGoods = (data) => {
    return request({
        url: "/v1/shop-goods",
        method: 'POST',
        data
    })
}

// 商品详情
export const shopDetail = (data) => {
    return request({
        url: "/v1/goods-info",
        method: 'POST',
        data
    })
}

// 畅销产品
export const goodsCx = (data) => {
    return request({
        url: "/v1/goods-cx",
        method: 'POST',
        data
    })
}

// 相关产品
export const goodsXg = (data) => {
    return request({
        url: "/v1/goods-xg",
        method: 'POST',
        data
    })
}

// 添加到购物车
export const cartAdd = (data) => {
    return request({
        url: "/v1/cart-add",
        method: 'POST',
        data
    })
}

// 购物车详情
export const cart = () => {
    return request({
        url: "/v1/cart",
        method: 'POST'
    })
}

// 购物车加减
export const cartChange = (data) => {
    return request({
        url: "/v1/cart-count",
        method: 'POST',
        data
    })
}

// 删除购物车商品
export const cartDel = (data) => {
    return request({
        url: "/v1/cart-del",
        method: 'POST',
        data
    })
}

// 购物车-地址信息
export const orderAddress = () => {
    return request({
        url: "/v1/order-address-list",
        method: 'POST'
    })
}

// 购物车-送货信息
export const deliveryInfo = () => {
    return request({
        url: "/v1/order-delivery-info",
        method: 'POST'
    })
}


// 地址添加
export const addressAdd = (data) => {
    return request({
        url: "/v1/buyer-address-add",
        method: 'POST',
        data
    })
}

// 设置为默认地址
export const setDefault = (data) => {
    return request({
        url: "/v1/buyer-address-set-default",
        method: 'POST',
        data
    })
}

// 付款信息
export const orderPay = () => {
    return request({
        url: "/v1/order-payment",
        method: 'POST'
    })
}

// 购物车-订单提交
export const orderSubmit = (data) => {
    return request({
        url: "/v1/order-confirmation",
        method: 'POST',
        data
    })
}

// 购物车-查看订单
export const orderConfirmed = (data) => {
    return request({
        url: "/v1/order-confirmed",
        method: 'POST',
        data
    })
}

// 获取地址列表
export const addressList = () => {
    return request({
        url: "/v1/buyer-address",
        method: 'POST'
    })
}

// 删除地址
export const addressDel = (data) => {
    return request({
        url: "/v1/buyer-address-del",
        method: 'POST',
        data
    })
}

// 修改地址
export const addressEdit = (data) => {
    return request({
        url: "/v1/buyer-address-update",
        method: 'POST',
        data
    })
}

// 获取默认地址
export const defAddress = () => {
    return request({
        url: "/v1/buyer-address-detail",
        method: 'POST'
    })
}

// 买家个人信息
export const buyerInfo = () => {
    return request({
        url: "/v1/buyer-info",
        method: 'POST'
    })
}

// 修改买家个人信息
export const buyerInfoEidt = (data) => {
    return request({
        url: "/v1/buyer-up-info",
        method: 'POST',
        data
    })
}

// 购买历史列表
export const buyerOrder = (data) => {
    return request({
        url: "/v1/buyer-order",
        method: 'POST',
        data
    })
}

// 确认订单
export const confirmOrder = (data) => {
    return request({
        url: "/v1/buyer-order-confirm",
        method: 'POST',
        data
    })
}

// 订单详情
export const orderDetails = (data) => {
    return request({
        url: "/v1/buyer-order-details",
        method: 'POST',
        data
    })
}

// 订单退款
export const orderRefund = (data) => {
    return request({
        url: "/v1/buyer-order-refund",
        method: 'POST',
        data
    })
}

// 联系卖家
export const contactSeller = (data) => {
    return request({
        url: "/v1/buyer-contact-seller",
        method: 'POST',
        data
    })
}

// 会话列表
export const huihuaList = (data) => {
    return request({
        url: "/v1/buyer-conversations-seller-list",
        method: 'POST',
        data
    })
}

// 会话内容列表
export const huiContentList = (data) => {
    return request({
        url: "/v1/buyer-conversations-content-list",
        method: 'POST',
        data
    })
}

// 添加会话内容
export const contentAdd = (data) => {
    return request({
        url: "/v1/buyer-conversations-content-add",
        method: 'POST',
        data
    })
}

// 退款列表
export const refundList = () => {
    return request({
        url: "/v1/buyer-order-refund-list",
        method: 'POST'
    })
}

// 钱包余额
export const buyerBalance = () => {
    return request({
        url: "/v1/buyer-balance",
        method: 'POST'
    })
}

// 钱包充值列表
export const rechargeList = () => {
    return request({
        url: "/v1/buyer-recharge-list",
        method: 'POST'
    })
}

// 钱包充值
export const rechargeAdd = (data) => {
    return request({
        url: "/v1/buyer-recharge-add",
        method: 'POST',
        data
    })
}

// 商品分类排序
export const goodsBy = () => {
    return request({
        url: "/v1/p/goods-order-by",
        method: 'POST'
    })
}

// 分类商品
export const sysGoods = (data) => {
    return request({
        url: "/v1/search-sys-goods",
        method: 'POST',
        data
    })
}

// 首页购物车和收藏统计
export const memberCount = () => {
    return request({
        url: "/v1/member-count",
        method: 'POST'
    })
}

// 条款条件
export const buyerTerms = () => {
    return request({
        url: "/v1/p/terms",
        method: 'POST'
    })
}

// 退货政策
export const returnPolicy = () => {
    return request({
        url: "/v1/p/return-policy",
        method: 'POST'
    })
}
// 支持政策
export const supportPolicy = () => {
    return request({
        url: "/v1/p/support-policy",
        method: 'POST'
    })
}
// 隐私政策
export const privacyPolicy = () => {
    return request({
        url: "/v1/p/privacy-policy",
        method: 'POST'
    })
}

// 获取产品信息
export const buyer = () => {
    return request({
        url: "/v1/buyer",
        method: 'POST'
    })
}

// 畅销产品
export const cxProduct = () => {
    return request({
        url: "/v1/h/g-xx",
        method: 'POST'
    })
}

// 服装
export const fzProduct = () => {
    return request({
        url: "/v1/h/g-nz",
        method: 'POST'
    })
}

// 会话统计
export const huihtongji = () => {
    return request({
        url: "/v1/buyer-conversations-seller-count",
        method: 'POST'
    })
}

// 男装与时尚
export const nzProduct = () => {
    return request({
        url: "/v1/h/g-by",
        method: 'POST'
    })
}

// 电脑及配件
export const computerProduct = () => {
    return request({
        url: "/v1/h/g-computer",
        method: 'POST'
    })
}

// 汽车摩托车
export const automobileProduct = () => {
    return request({
        url: "/v1/h/g-automobile",
        method: 'POST'
    })
}

// 户外及运动
export const sportsProduct = () => {
    return request({
        url: "/v1/h/g-sports",
        method: 'POST'
    })
}

// 商品详情买家
export const goodsInfo = (data) => {
    return request({
        url: "/v1/sys-goods-info",
        method: 'POST',
        data
    })
}

// 商品详情-相关产品
export const goodsxg = (data) => {
    return request({
        url: "/v1/sys-goods-xg",
        method: 'POST',
        data
    })
}

// 商品详情-畅销产品
export const goodscx = (data) => {
    return request({
        url: "/v1/sys-goods-cx",
        method: 'POST',
        data
    })
}

// 商品详情-发送信息给卖家
export const contactBuyer = (data) => {
    return request({
        url: "/v1/buyer-contact-goods",
        method: 'POST',
        data
    })
}