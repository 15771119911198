<template>
    <div class="bigbox">
        <div class="tickets">
            <span>Support Ticket</span>
        </div>
        <div class="createbox" @click="tcshow = true">
            <span>+</span>
            <span>Create a Ticket</span>
        </div>
        <div class="tabbox">
            <div class="tabtitle">
                <span>Tickets</span>
            </div>
            <div class="tabitem">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="tikcet" label="Ticket ID" width="300" />
                    <el-table-column prop="sending" label="Sending Date" width="310" />
                    <el-table-column prop="subject" label="Subject" width="300" />
                    <el-table-column prop="status" label="Status" width="300">
                        <template #default="scope">
                            <span class="span1">{{ scope.row.status }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="options" :label="$t('seller.myLoan.options')" width="300">
                        <template #default="scope">
                            <span class="span2" @click="toDetails">{{ scope.row.options }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="fenye">
            <el-pagination background layout="prev, pager, next" :total="1000" />
        </div>
        <!-- 弹窗 -->
        <div class="tczhezhao" v-if="tcshow">
            <div class="tcbox">
                <div class="btticket">
                    <span>Create a Ticket</span>
                </div>
                <div class="subjbox">
                    <span>Subject</span>
                    <input type="text" placeholder="Subject">
                </div>
                <div class="areabox">
                    <span>Providea detailed description</span>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
                <div class="photobox">
                    <span>Photo</span>
                    <div class="updateimg">
                        <el-upload
                            v-model:file-list="fileList"
                            class="upload-demo"
                            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                            multiple
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :limit="3"
                            :on-exceed="handleExceed"
                            list-type="picture"
                        >
                            <span>Browse</span>
                            <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                        </el-upload>
                    </div>
                </div>
                <div class="btnbox">
                    <span class="span01">Cancel</span>
                    <span class="span02">Send Ticket</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            fileList: [], //上传的图片
            tcshow: false, //弹窗
            tableData: [
                {tikcet: '#2147483647 ',sending: '2023-07-03 08:56:15',subject: '仨的阿瑟 ',status: 'Pending',options: 'View Details'},
                {tikcet: '#2147483647 ',sending: '2023-07-03 08:56:15',subject: '仨的阿瑟 ',status: 'Pending',options: 'View Details'},
                {tikcet: '#2147483647 ',sending: '2023-07-03 08:56:15',subject: '仨的阿瑟 ',status: 'Pending',options: 'View Details'},
                {tikcet: '#2147483647 ',sending: '2023-07-03 08:56:15',subject: '仨的阿瑟 ',status: 'Pending',options: 'View Details'},
                {tikcet: '#2147483647 ',sending: '2023-07-03 08:56:15',subject: '仨的阿瑟 ',status: 'Pending',options: 'View Details'}
            ]
        }
    },
    methods:{
        // 查看详情
        toDetails(){
            this.$router.push('/Home/ViewDetails')
        }
    }
}
</script>
<style lang="less" scoped>
    .bigbox {
        width: 100%;
        box-sizing: border-box;
        padding: 0 25px;
        .tickets {
            width: 100%;
            height: 60px;
            font-size: 18px;
            font-weight: 600;
            text-align: left;
            line-height: 60px;
        }
        .createbox {
            width: 100%;
            height: 196px;
            border: 1px solid #DFDFDF;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            span:nth-child(1){
                width: 60px;
                height: 60px;
                background: #CACACA;
                border-radius: 50%;
                font-size: 48px;
                font-family: Arial;
                font-weight: bold;
                color: #FFFFFF;
                text-align: center;
                line-height: 60px;
            }
            span:nth-child(2){
                font-size: 18px;
                margin-left: 20px;
            }
        }
        .tabbox {
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 5px;
            margin-top: 30px;
            .tabtitle {
                width: 100%;
                height: 55px;
                font-size: 18px;
                text-align: left;
                line-height: 55px;
                box-sizing: border-box;
                padding-left: 30px;
            }
            .tabitem {
                width: 100%;
                box-sizing: border-box;
                padding: 0 30px;
                text-align: left;
                .span1 {
                    display: inline-block;
                    padding: 0 5px;
                    height: 23px;
                    background: #EC486B;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #FEFEFE;
                }
                .span2 {
                    display: inline-block;
                    color: #0277BD;
                    cursor: pointer;
                    height: 40px;
                    line-height: 40px;
                }
            }
        }
        .fenye {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .tczhezhao {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
            background: rgba(0, 0, 0, 0.5);
            .tcbox {
                width: 600px;
                background: #FFFFFF;
                border-radius: 5px;
                margin: auto;
                margin-top: 150px;
                padding-bottom: 30px;
                .btticket {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    text-align: left;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: solid 1px #DCDCDC;
                    font-size: 18px;
                    font-weight: 600;
                }
                .subjbox {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0 30px;
                    margin-top: 20px;
                    font-size: 14px;
                    input {
                        height: 60px;
                        width: 390px;
                        outline: none;
                        border: solid 1px #DCDCDC;
                        box-sizing: border-box;
                        padding-left: 10px;
                    }
                }
                .areabox {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0 30px;
                    font-size: 14px;
                    margin-top: 20px;
                    textarea {
                        width: 390px;
                        height: 100px;
                        font-size: 14px;
                        resize: none;
                        box-sizing: border-box;
                        border: solid 1px #DCDCDC;
                        padding: 10px;
                        outline: none;
                    }
                    span {
                        width: 120px;
                        text-align: left;
                    }
                }
                .photobox {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0 30px;
                    font-size: 14px;
                    margin-top: 20px;
                    .updateimg {
                        :deep(.el-upload) {
                            width: 390px;
                            height: 60px;
                            border: 1px solid #DFDFDF;
                            border-radius: 4px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            span:nth-child(1){
                                width: 80px;
                                height: 60px;
                                line-height: 60px;
                                background: #DCDCDC;
                                border-radius: 4px;
                            }
                            span:nth-child(2){
                                margin-left: 20px;
                                color: #969696;
                            }
                        }
                    }
                }
                .btnbox {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    justify-content: flex-end;
                    box-sizing: border-box;
                    padding-right: 30px;
                    margin-top: 20px;
                    span {
                        width: 140px;
                        height: 60px;
                        border: 1px solid #00ADF1;
                        border-radius: 5px; 
                        text-align: center;
                        line-height: 60px;
                        font-size: 18px;
                        cursor: pointer;
                    }
                    .span01 {
                        color: #00ADF1;
                    }
                    .span02 {
                        background: #00ADF1;
                        color: #FFFFFF;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
</style>