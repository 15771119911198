<template>
 <!-- 订单详情 -->
    <div class="pc">
        <div class='bodys'>
            <div class="allbox">
                <div class="title">
                    <span>{{ $t('seller.orderDetail.orderDetail') }}</span>
                </div>
                <div class="centbox">
                    <div class="leftuser">
                        <span>{{ orderSummary.customer }}</span>
                        <span>{{ orderSummary.phone }}</span>
                        <span>{{ orderSummary.email }}</span>
                        <span>{{ orderSummary.shipping_address }}</span>
                    </div>
                    <div class="rightorder">
                        <div class="itemorder">
                            <span>{{ $t('seller.order.order') }}</span>
                            <span style="color: #62a3d2;">{{ orderSummary.order_code }}</span>
                        </div>
                        <div class="itemorder">
                            <span>{{ $t('seller.orderDetail.orderStatus') }}</span>
                            <span class="span1">{{ orderSummary.order_status }}</span>
                        </div>
                        <div class="itemorder">
                            <span>{{ $t('seller.orderDetail.orderDate') }}</span>
                            <span>{{ orderSummary.order_date }}</span>
                        </div>
                        <div class="itemorder">
                            <span>{{ $t('seller.orderDetail.totalAmount') }}</span>
                            <span>{{ orderSummary.total_order_amount }}</span>
                        </div>
                        <div class="itemorder">
                            <span>{{ $t('seller.orderDetail.paymentMethod') }}</span>
                            <span>{{ orderSummary.payment_method }}</span>
                        </div>
                        <div class="itemorder">
                            <span>{{ $t('seller.orderDetail.additionalInfo') }}</span>
                            <span>{{ orderSummary.additional_info }}</span>
                        </div>
                    </div>
                </div>
                <div class="tabbox">
                    <el-table :data="orderList" border style="width: 100%">
                        <el-table-column type="index" label="#" width="100" />
                        <el-table-column prop="main_img" :label="$t('seller.orderDetail.photo')" width="120">
                            <template #default="scope">
                                <img :src="scope.row.main_img" >
                            </template>
                        </el-table-column>
                        <el-table-column prop="goods_name" :label="$t('seller.orderDetail.description')" width="700" />
                        <el-table-column prop="delivery_type" :label="$t('seller.orderDetail.deliveryType')" width="240" />
                        <el-table-column prop="count" :label="$t('seller.orderDetail.qty')" width="120" />
                        <el-table-column prop="unit_price" :label="$t('seller.orderDetail.price')" width="110" />
                        <el-table-column prop="total_price" :label="$t('seller.orderDetail.total')" />
                    </el-table>
                </div>
                <div class="heji">
                    <div class="itemhj">
                        <span>{{ $t('seller.orderDetail.subTotal') }}:</span>
                        <span>{{ orderAmmount.subtotal }}</span>
                    </div>
                    <div class="itemhj">
                        <span>{{ $t('seller.orderDetail.tax') }}:</span>
                        <span>{{ orderAmmount.tax }}</span>
                    </div>
                    <div class="itemhj">
                        <span>{{ $t('seller.orderDetail.shipping') }}:</span>
                        <span>{{ orderAmmount.shipping }}</span>
                    </div>
                    <div class="itemhj">
                        <span>{{ $t('seller.orderDetail.coupon') }}:</span>
                        <span>{{ orderAmmount.coupon }}</span>
                    </div>
                    <div class="itemhj">
                        <span>{{ $t('seller.orderDetail.total') }}:</span>
                        <span class="span1">{{ orderAmmount.total }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class='bodys'>
            <div class="allbox">
                <div class="title">
                    <span>{{ $t('seller.orderDetail.orderDetail') }}</span>
                </div>
                <div class="centbox">
                    <div class="leftuser">
                        <span>{{ orderSummary.customer }}</span>
                        <!-- <span>1507****423</span> -->
                        <span>{{ orderSummary.email }}</span>
                        <span>{{ orderSummary.shipping_address }}</span>
                    </div>
                    <div class="rightorder">
                        <div class="itemorder">
                            <span>{{ $t('seller.order.order') }}</span>
                            <span style="color: #62a3d2;">{{ orderSummary.order_code }}</span>
                        </div>
                        <div class="itemorder">
                            <span>{{ $t('seller.orderDetail.orderStatus') }}</span>
                            <span class="span1">{{ orderSummary.order_status }}</span>
                        </div>
                        <div class="itemorder">
                            <span>{{ $t('seller.orderDetail.orderDate') }}</span>
                            <span>{{ orderSummary.order_date }}</span>
                        </div>
                        <div class="itemorder">
                            <span>{{ $t('seller.orderDetail.totalAmount') }}</span>
                            <span>{{ orderSummary.total_order_amount }}</span>
                        </div>
                        <div class="itemorder">
                            <span>{{ $t('seller.orderDetail.paymentMethod') }}</span>
                            <span>{{ orderSummary.payment_method }}</span>
                        </div>
                        <div class="itemorder">
                            <span>{{ $t('seller.orderDetail.additionalInfo') }}</span>
                            <span>{{ orderSummary.additional_info }}</span>
                        </div>
                    </div>
                </div>
                <div class="tabbox">
                    <el-table :data="orderList" border style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column prop="main_img" :label="$t('seller.orderDetail.photo')" width="120">
                            <template #default="scope">
                                <img :src="scope.row.main_img" >
                            </template>
                        </el-table-column>
                        <el-table-column prop="goods_name" :label="$t('seller.orderDetail.description')" width="700" />
                        <el-table-column prop="delivery_type" :label="$t('seller.orderDetail.deliveryType')" width="240" />
                        <el-table-column prop="count" :label="$t('seller.orderDetail.qty')" width="130" />
                        <el-table-column prop="unit_price" :label="$t('seller.orderDetail.price')" width="110" />
                        <el-table-column prop="total_price" :label="$t('seller.orderDetail.total')" width="180px"/>
                    </el-table>
                </div>
                <div class="heji">
                    <div class="itemhj">
                        <span>{{ $t('seller.orderDetail.subTotal') }}:</span>
                        <span>{{ orderAmmount.subtotal }}</span>
                    </div>
                    <div class="itemhj">
                        <span>{{ $t('seller.orderDetail.tax') }}:</span>
                        <span>{{ orderAmmount.tax }}</span>
                    </div>
                    <div class="itemhj">
                        <span>{{ $t('seller.orderDetail.shipping') }}:</span>
                        <span>{{ orderAmmount.shipping }}</span>
                    </div>
                    <div class="itemhj">
                        <span>{{ $t('seller.orderDetail.coupon') }}:</span>
                        <span>{{ orderAmmount.coupon }}</span>
                    </div>
                    <div class="itemhj">
                        <span>{{ $t('seller.orderDetail.total') }}:</span>
                        <span class="span1">{{ orderAmmount.total }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {orderDetails} from '@/api/sellerapi'
    import { ElMessage } from 'element-plus'
    export default {
        data () {
            return {
                id: '', //订单ID
                orderSummary: {}, //订单摘要
                orderAmmount: {}, //订单金额
                orderList: [], //订单列表
                tableData: [
                    {
                        q: require('@/assets/img/touxiang.png'),
                        w:'Ring Sizer Measuring Tool, 27 PCS Premium Ring Measurement Tool, US Ring Size 0-13 with Half Size, 1 PCS Finger Ring Sizing Measure Gauge, by UUBAAR',
                        e: 'Home Delivery',
                        r: '1',
                        t: '$4.94',
                        y: '$4.99'
                    }
                ]
            }
        },
        mounted(){
            this.id = this.$route.query.id
            this.getOrderDetail()
        },
        methods:{
            // 获取订单详情
            async getOrderDetail(){
                const {data: res} = await orderDetails({
                    id: this.id
                })
                if(res.status == 200) {
                    this.orderSummary = res.success.order_summary
                    this.orderAmmount = res.success.order_ammount
                    this.orderList = res.success.order_goods_list
                    console.log(res)
                }else {
                    ElMessage.error(res.message)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}
	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}
    .pc {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .allbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                margin-top: 30px;
                padding-bottom: 50px;
                .title {
                    width: 100%;
                    height: 55px;
                    font-size: 18px;
                    text-align: left;
                    line-height: 55px;
                    box-sizing: border-box;
                    padding-left: 30px;
                    border-bottom: 1px solid #DFDFDF;
                }
                .centbox {
                    width: 96%;
                    height: 200px;
                    box-sizing: border-box;
                    margin: auto;
                    border-bottom: 1px solid #DFDFDF;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .leftuser {
                        font-size: 14px;
                        line-height: 24px;
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        span:nth-child(2){
                            margin-top: 20px;
                        }
                    }
                    .rightorder {
                        margin-right: 150px;
                        .itemorder {
                            font-size: 14px;
                            line-height: 25px;
                            display: flex;
                            justify-content: flex-start;
                            span:nth-child(1) {
                                width: 200px;
                                text-align: left;
                            }
                            .span1 {
                                padding: 0 10px;
                                color: #fff;
                                background: #0ABB75;
                            }
                        }
                    }
                }
                .tabbox {
                    width: 96%;
                    margin: auto;
                    border: 1px solid #DFDFDF;
                    border-radius: 5px;
                    margin-top: 30px;
                    img {
                        width: 70px;
                        height: auto;
                    }
                }
                .heji {
                    width: 96%;
                    margin: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    margin-top: 20px;
                    .itemhj {
                        line-height: 40px;
                        display: flex;
                        justify-content: space-between;
                        width: 200px;
                        span:nth-child(1){
                            text-align: left;
                        }
                        .span1 {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
    .phone {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            .allbox {
                width: 100%;
                background: #FFFFFF;
                border: 2px solid #DFDFDF;
                border-radius: 5px;
                margin-top: 30px;
                padding-bottom: 50px;
                .title {
                    width: 100%;
                    height: 60px;
                    font-size: 28px;
                    text-align: left;
                    line-height: 60px;
                    box-sizing: border-box;
                    padding-left: 30px;
                    border-bottom: 2px solid #DFDFDF;
                }
                .centbox {
                    width: 96%;
                    box-sizing: border-box;
                    margin: auto;
                    border-bottom: 2px solid #DFDFDF;
                    display: flex;
                    flex-direction: column;
                    .leftuser {
                        font-size: 24px;
                        line-height: 24px;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        margin-top: 20px;
                        span:nth-child(2){
                            margin-top: 20px;
                        }
                    }
                    .rightorder {
                        margin-top: 30px;
                        .itemorder {
                            font-size: 24px;
                            line-height: 60px;
                            display: flex;
                            justify-content: space-between;
                            span:nth-child(1) {
                                text-align: left;
                            }
                            .span1 {
                                padding: 0 10px;
                                height: 40px;
                                line-height: 40px;
                                color: #fff;
                                background: #0ABB75;
                                border-radius: 5px;
                            }
                        }
                    }
                }
                .tabbox {
                    width: 96%;
                    margin: auto;
                    border: 2px solid #DFDFDF;
                    border-radius: 5px;
                    margin-top: 30px;
                    img {
                        width: 90px;
                        height: auto;
                    }
                }
                .heji {
                    width: 96%;
                    margin: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    margin-top: 20px;
                    .itemhj {
                        font-size: 24px;
                        line-height: 40px;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        span:nth-child(1){
                            text-align: left;
                        }
                        .span1 {
                            font-size: 28px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
</style>