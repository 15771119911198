<template>
    <!-- 邀请 -->
    <div class="bigboxs">
        <div class="betitle">
            <span>Be a Brushing User</span>
        </div>
        <div class="basicbox">
            <div class="basicinfo">
                <span>{{ $t('seller.appliedRefundRequest.basicInfo') }}</span>
            </div>
            <div class="codebox">
                <span>Invite Code*</span>
                <input type="text" placeholder="Invite Code">
            </div>
            <div class="asabox">
                <span>Register As a Brushing User</span>
            </div>
        </div>
    </div>
</template>



<style lang="less" scoped>
    .bigboxs {
        width: 1080px;
        .betitle {
            font-size: 18px;
            font-family: Arial;
            font-weight: bold;
            text-align: left;
        }
        .basicbox {
            width: 100%;
            background: #fff;
            box-sizing: border-box;
            padding: 0 30px 30px 30px;
            margin-top: 20px;
            .basicinfo {
                width: 100%;
                height: 60px;
                font-size: 18px;
                font-family: Arial;
                font-weight: 400;
                text-align: left;
                line-height: 60px;
            }
            .codebox {
                font-size: 18px;
                font-family: Arial;
                input {
                    width: 850px;
                    height: 50px;
                    background: #F6F6F6;
                    border: none;
                    outline: none;
                    font-size: 18px;
                    box-sizing: border-box;
                    padding-left: 15px;
                    margin-left: 15px;
                }
            }
            .asabox {
                width: 100%;
                margin-top: 20px;
                span {
                    display: inline-block;
                    padding: 0 20px;
                    height: 50px;
                    background: #00ADF1;
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 50px;
                    text-align: center;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
    }
</style>