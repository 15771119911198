import axios from 'axios'

// let baseURL = 'http://u-api.jdc5g.com' //测试地址
let baseURL = 'https://api.lianbang-shopping.com' //客户地址 
// let baseURL = '/api' //本地测试地址


const request = axios.create({
    baseURL: baseURL,
    timeout: 300000 //请求超时时间
})

// 请求拦截器
request.interceptors.request.use(config => {
    const token = sessionStorage.getItem('member_token')
    const language = sessionStorage.getItem('language')
    if (language) {
        config.headers['Language'] = language
    } else {
        config.headers['Language'] = 'en'
    }
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}, err => {
    return Promise.reject(err)
}
)

//响应拦截器
request.interceptors.response.use(res => {
    return res
}, err => {
    return Promise.reject(err)
}
)

export default request