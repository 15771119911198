<template>
    <div class="pc">
        <div class="dhbigbox">
            <div class="titles">
                <span>{{ $t('buyer.my.conversations') }}</span>
            </div>
            <div class="dhbox">
                <div class="itemdh" v-for="item in huihList" :key="item.id">
                    <div class="lefthead">
                        <div class="head">
                            <img :src="item.member_head" alt="">
                        </div>
                        <div class="uname">
                            <span>{{ item.title }}</span>
                            <span>{{ item.email }}</span>
                        </div>
                    </div>
                    <div class="rightxx">
                        <div class="xiaoxi" @click="toduihua(item.id)">
                            <span>{{ item.content }}</span>
                            <span v-if="item.new_status == 1">new</span>
                        </div>
                        <span>{{ item.created_at }}</span>
                    </div>
                </div>
            </div>
            <div class="fenye">
                <el-pagination
                    small
                    background
                    layout="prev, pager, next"
                    :total="count"
                    class="mt-4"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
        </div>
    </div>
    <div class="phone">
        <div class="dhbigbox">
            <div class="titles">
                <span>{{ $t('buyer.my.conversations') }}</span>
            </div>
            <div class="dhbox">
                <div class="itemdh" v-for="item in huihList" :key="item.id">
                    <div class="lefthead">
                        <div class="head">
                            <img :src="item.member_head" alt="">
                        </div>
                        <div class="uname">
                            <span>{{ item.title }}</span>
                            <span>{{ item.email }}</span>
                        </div>
                    </div>
                    <div class="rightxx">
                        <div class="xiaoxi" @click="toduihua(item.id)">
                            <span>{{ item.content }}</span>
                            <span v-if="item.new_status == 1">new</span>
                        </div>
                        <span>{{ item.created_at }}</span>
                    </div>
                </div>
            </div>
            <div class="fenye">
                <el-pagination
                    small
                    background
                    layout="prev, pager, next"
                    :total="count"
                    class="my-pagination"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {huihuaList} from '@/api/buyerapi'
    export default {
        data(){
            return {
                page: 1, //页码
                huihList: [],//会话列表
                count: 0,
            }
        },
        mounted(){
            this.gethuihList()
        },
        methods: {
            // 获取会话列表
            async gethuihList(){
                const {data:res} = await huihuaList({
                    page: this.page
                })
                console.log(res)
                if(res.status == 200) {
                    this.huihList = res.success.list
                    this.count = parseInt(res.success.count)
                }
            },
            // 去对话
            toduihua(id){
                this.$router.push({path: '/index/mypage/conversationswith',query:{id:id}})
            },
            // 上一页
			prevChange(e){
				this.page = e
				this.gethuihList()
			},
			// 下一页
			nextChange(e){
				this.page = e
				this.gethuihList()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
				this.gethuihList()
			}
        }
    }
</script>



<style lang="less" scoped>

    // 横屏
    @media all and (orientation: landscape) {
        .pc {
            display: block !important;
        }
        .phone {
            display: none !important;
        }
    }

    /* 竖屏*/
    @media all and (orientation: portrait) {
        .pc {
            display: none !important;
        }
        .phone {
            display: block !important;
        }
    }

    .pc {
        .dhbigbox {
            width: 1080px;
            .titles {
                font-size: 18px;
                font-family: Arial;
                font-weight: 600;
                text-align: left;
            }
            .dhbox {
                width: 100%;
                box-sizing: border-box;
                padding: 0 30px;
                background: #fff;
                margin-top: 20px;
                min-height: 120px;
                .itemdh {
                    width: 100%;
                    min-height: 100px;
                    border-bottom: solid 1px #e6e6e6;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .lefthead {
                        display: flex;
                        align-items: center;
                        .head {
                            width: 66px;
                            height: 66px;
                            border-radius: 50%;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .uname {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin-left: 15px;
                            span:nth-child(1) {
                                font-size: 22px;
                            }
                            span:nth-child(2){
                                font-size: 16px;
                                color: #969696;
                                margin-top: 5px;
                            }
                        }
                    }
                    .rightxx {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .xiaoxi {
                            font-size: 14px;
                            color: #000;
                            width: 600px;
                            text-align: left;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            line-height: 20px;
                            cursor: pointer;
                            &>span:nth-child(2){
                                background: red;
                                color: #fff;
                                padding: 2px 5px;
                                border-radius: 5px;
                                margin-left: 5px;
                            }
                        }
                        &>span {
                            color: #969696;
                            font-size: 14px;
                            margin-top: 5px;
                        }
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: flex-end;
                box-sizing: border-box;
            }
        }
    }
    .phone {
        .dhbigbox {
            width: 96%;
            margin: auto;
            .titles {
                font-size: 28px;
                font-family: Arial;
                font-weight: 600;
                text-align: left;
                line-height: 60px;
            }
            .dhbox {
                width: 100%;
                box-sizing: border-box;
                padding: 30px;
                background: #fff;
                margin-top: 20px;
                .itemdh {
                    width: 100%;
                    border-bottom: solid 1px #e6e6e6;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .lefthead {
                        display: flex;
                        align-items: center;
                        .head {
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .uname {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin-left: 15px;
                            span:nth-child(1) {
                                font-size: 32px;
                            }
                            span:nth-child(2){
                                font-size: 26px;
                                color: #969696;
                                margin-top: 5px;
                            }
                        }
                    }
                    .rightxx {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-top: 20px;
                        .xiaoxi {
                            font-size: 24px;
                            color: #000;
                            width: 100%;
                            text-align: left;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            line-height: 20px;
                            cursor: pointer;
                            &>span:nth-child(2){
                                background: red;
                                color: #fff;
                                padding: 2px 5px;
                                border-radius: 5px;
                                margin-left: 5px;
                            }
                        }
                        &>span {
                            color: #969696;
                            font-size: 24px;
                            margin-top: 5px;
                        }
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 50px;
                        height: 50px;
                    }
                    :deep(.number) {
                        font-size: 40px !important;
                    }
                    :deep(.is-first) {
                        width: 50px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 40px !important;
                    }
                }
            }
        }
    }
</style>