<template>
    <!-- 退货政策页面 -->
    <div class="pc">
        <div class="allbox">
            <div class="titles">
                <span>{{ title }}</span>
            </div>
            <div class="htmlbox" v-html="content"></div>
        </div>
    </div>
    <div class="phone">
        <div class="allbox">
            <div class="titles">
                <span>{{ title }}</span>
            </div>
            <div class="htmlbox" v-html="content"></div>
        </div>
    </div>
</template>

<script>
    import {buyerTerms,returnPolicy,supportPolicy,privacyPolicy} from "@/api/buyerapi"
    export default {
        data() {
            return {
                title: '',//标题
                id: '', //id
                content: '', //条款条件
            }
        },
        mounted() {
            this.id = this.$route.query.id
            if(this.id == 1) {
                this.getbuyerTerms()
            }else if(this.id == 2) {
                this.getReturnPolicy()
            }else if(this.id == 3) {
                this.getSupportPolicy()
            }else if(this.id == 4) {
                this.getPrivacyPolicy()
            }
        },
        watch: {
            $route: {
                handler(newVal) {
                    this.id = this.$route.query.id
                    if(this.id == 1) {
                        this.getbuyerTerms()
                    }else if(this.id == 2) {
                        this.getReturnPolicy()
                    }else if(this.id == 3) {
                        this.getSupportPolicy()
                    }else if(this.id == 4) {
                        this.getPrivacyPolicy()
                    }
                },
                deep: true
            }
        },
        methods: {
            // 条款条件
            async getbuyerTerms(){
                const {data: res} = await buyerTerms()
                if(res.status == 200) {
                    this.content = res.success.content
                    this.title = res.success.title
                }
            },
            // 退货政策
            async getReturnPolicy(){
                const {data: res} = await returnPolicy()
                if(res.status == 200) {
                    this.content = res.success.content
                    this.title = res.success.title
                }
            },
            // 支持政策
            async getSupportPolicy(){
                const {data: res} = await supportPolicy()
                if(res.status == 200) {
                    this.content = res.success.content
                    this.title = res.success.title
                }
            },
            // 隐私政策
            async getPrivacyPolicy(){
                const {data: res} = await privacyPolicy()
                if(res.status == 200) {
                    this.content = res.success.content
                    this.title = res.success.title
                }
            }
        }
    }

</script>



<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}
    .pc {
        .allbox {
            width: 1380px;
            margin: auto;
            .titles {
                font-size: 24px;
                font-family: Arial;
                font-weight: 400;
                text-align: left;
                height: 60px;
                line-height: 60px;
            }
            .htmlbox {
                width: 100%;
                background: #fff;
                text-align: left;
                box-sizing: border-box;
                padding: 20px;
            }
        }
    }
    .phone {
        .allbox {
            width: 96%;
            margin: auto;
            .titles {
                font-size: 30px;
                font-family: Arial;
                font-weight: 400;
                text-align: left;
                height: 60px;
                line-height: 60px;
            }
            .htmlbox {
                width: 100%;
                background: #fff;
                text-align: left;
                box-sizing: border-box;
                padding: 20px;
            }
        }
    }
    
</style>