<template>
	<!-- 购买历史 -->
	<div class="pc">
		<div class="bigbox">
			<div class="historys">
				<span>{{ $t('buyer.my.purchaseHistory') }}</span>
			</div>
			<div class="tablebox">
				<el-table :data="orderList" style="width: 100%">
					<el-table-column prop="" type="index" label="#" width="40"/>
					<el-table-column prop="order_no" :label="$t('buyer.purchaseHistory.code')" width="170">
						<template #default="scope">
							<span class="spans" @click="toDetail(scope.row.id)">{{ scope.row.order_no }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="buyer_pay_time" :label="$t('buyer.purchaseHistory.date')" width="160" />
					<el-table-column prop="amount" :label="$t('buyer.purchaseHistory.amount')" width="90"/>
					<el-table-column prop="delivery_status" :label="$t('buyer.purchaseHistory.deliveryStatus')" width="90"/>
					<el-table-column prop="buyer_pay" :label="$t('buyer.purchaseHistory.paymentStatus')" width="85">
						<template #default="scope">
							<span class="span1">{{ scope.row.buyer_pay }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="buyer_status" :label="$t('buyer.purchaseHistory.confirmOrder')" width="140">
						<template #default="scope">
							<span class="span2" v-if="scope.row.confirm_button == 1" @click="confirmOrderChange(scope.row.id)">{{ $t('buyer.purchaseHistory.confirmOrder') }}</span>
							<span class="span2" v-else>{{ scope.row.buyer_status }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="id" :label="$t('buyer.purchaseHistory.contactSeller')" width="140">
						<template #default="scope">
							<span class="span3" @click="showtc(scope.row.id)">{{ $t('buyer.purchaseHistory.contactSeller') }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="" :label="$t('seller.myLoan.options')" width="100">
						<template #default="scope">
							<img @click="toDetail(scope.row.id)" src="../../assets/img/history-yanjing.png" alt="">
							<!-- <img src="../../assets/img/history-xiazai.png" alt="">
							<img src="../../assets/img/history-shanchu.png" alt=""> -->
						</template>
					</el-table-column>
  				</el-table>
			</div>
			<div class="fenye">
				<el-pagination
					small
					background
					layout="prev, pager, next"
					:total="count"
					class="mt-4"
					:default-page-size="10"
					@prev-click="prevChange"
					@next-click="nextChange"
					@current-change="currentChaneg"
  				/>
			</div>
		</div>
		<!-- 弹窗 -->
		<div class="zhezhao" v-show="show">
			<div class="querybox">
				<div class="titles">
					{{ $t('buyer.shoppingInfo.anyAdditionalInfo') }}
				</div>
				<div class="inpbox">
					<input type="text" :placeholder="$t('seller.order.title')" v-model="titles">
					<textarea v-model="content"></textarea>
				</div>
				<div class="bombox">
					<span class="span01" @click="guanbiTc">{{ $t('buyer.cart.cancel') }}</span>
					<span class="span02" @click="contactSller">{{ $t('seller.withdrawToWallet.send') }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="phone">
		<div class="bigbox">
			<div class="historys">
				<span>{{ $t('buyer.my.purchaseHistory') }}</span>
			</div>
			<div class="tablebox">
				<div class="tabbox" v-for="item in orderList" :key="item.id">
					<div class="itemtable">
						<p>{{ $t('buyer.purchaseHistory.code') }}</p>
						<span class="spans" @click="toDetail(item.id)">{{ item.order_no }}</span>
					</div>
					<div class="itemtable">
						<p>{{ $t('buyer.purchaseHistory.date') }}</p>
						<span>{{ item.buyer_pay_time }}</span>
					</div>
					<div class="itemtable">
						<p>{{ $t('buyer.purchaseHistory.amount') }}</p>
						<span>{{ item.amount }}</span>
					</div>
					<div class="itemtable">
						<p>{{ $t('buyer.purchaseHistory.deliveryStatus') }}</p>
						<span>{{ item.delivery_status }}</span>
					</div>
					<div class="itemtable">
						<p>{{ $t('buyer.purchaseHistory.paymentStatus') }}</p>
						<span class="span1">{{ item.buyer_pay }}</span>
					</div>
					<div class="itemtable">
						<p>{{ $t('buyer.purchaseHistory.confirmOrder') }}</p>
						<span class="span2" v-if="item.confirm_button == 1" @click="confirmOrderChange(item.id)">{{ $t('buyer.purchaseHistory.confirmOrder') }}</span>
						<span class="span2" v-else>{{ item.buyer_status }}</span>
					</div>
					<div class="itemtable">
						<p>{{ $t('buyer.purchaseHistory.contactSeller') }}</p>
						<span class="span3" @click="showtc(item.id)">{{ $t('buyer.purchaseHistory.contactSeller') }}</span>
					</div>
					<div class="itemtable">
						<p>{{ $t('seller.myLoan.options') }}</p>
						<img @click="toDetail(item.id)" src="../../assets/img/history-yanjing.png">
					</div>
				</div>
			</div>
			<div class="fenye">
				<el-pagination
					small
					background
					layout="prev, pager, next"
					:total="count"
					class="my-pagination"
					:default-page-size="10"
					@prev-click="prevChange"
					@next-click="nextChange"
					@current-change="currentChaneg"
  				/>
			</div>
		</div>
		<!-- 弹窗 -->
		<div class="zhezhao" v-show="show">
			<div class="querybox">
				<div class="titles">
					{{ $t('buyer.shoppingInfo.anyAdditionalInfo') }}
				</div>
				<div class="inpbox">
					<input type="text" :placeholder="$t('seller.order.title')" v-model="titles">
					<textarea v-model="content"></textarea>
				</div>
				<div class="bombox">
					<span class="span01" @click="guanbiTc">{{ $t('buyer.cart.cancel') }}</span>
					<span class="span02" @click="contactSller">{{ $t('seller.withdrawToWallet.send') }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {buyerOrder,confirmOrder,contactSeller} from '@/api/buyerapi'
	import { ElMessage } from 'element-plus'
	export default {
		data(){
			return {
				show: false, //弹窗
				page: 1,
				count: 0, //总数
				orderList: [],
				content: '', //会话内容
				titles: '', //标题
				id: '', //会话id
			}
		},
		mounted(){
			this.getOrderList()
		},
		methods: {
			// 获取订单列表
			async getOrderList(){
				const {data:res} = await buyerOrder({
					page: this.page
				})
				if(res.status == 200) {
					this.count = parseInt(res.success.count)
					this.orderList = res.success.list
					console.log(res)
				}else {
					ElMessage.error(res.message)
				}
			},
			// 确认订单
			async confirmOrderChange(id){
				const {data:res} = await confirmOrder({
					id: id
				})
				if(res.status == 200) {
					ElMessage.success(res.message)
					this.getOrderList()
				}else {
					ElMessage.error(res.message)
				}
			},
			// 联系卖家-发送信息
			async contactSller(){
				this.show = false
				const {data:res} = await contactSeller({
					id: this.id, //订单id
					title: this.titles, //会话标题
					content: this.content //会话内容
				})
				if(res.status == 200) {
					ElMessage.success(res.message)
					this.id = ''
					this.titles = ''
					this.content = ''
				}else {
					ElMessage.error(res.message)
					this.id = ''
					this.titles = ''
					this.content = ''
				}
			},
			// 显示弹窗
			showtc(id){
				this.show = true
				this.id = id
			},
			// 关闭弹窗
			guanbiTc(){
				this.show = false
				this.id = ''
				this.titles = ''
				this.content = ''
			},
			// 上一页
			prevChange(e){
				this.page = e
				this.getOrderList()
			},
			// 下一页
			nextChange(e){
				this.page = e
				this.getOrderList()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
				this.getOrderList()
			},
			// 去购买详情页
			toDetail(id){
				this.$router.push({path: '/index/mypage/orderdetails',query:{id:id}})
			}
		}
	}
</script>

<style lang="less" scoped>

	// 横屏
	@media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}

	.pc {
		.bigbox {
			width: 1080px;
			background: #fff;
			box-sizing: border-box;
			padding: 0 30px;
			.historys {
				font-size: 18px;
				font-family: Arial;
				font-weight: bold;
				color: #000000;
				line-height: 36px;
				text-align: left;
				border-bottom: solid 1px #F6F6F6;
			}
			.tablebox {
				img{
					width: 30px;
					height: 30px;
					margin: 0 5px;
					cursor: pointer;
				}
				.spans {
					color: #00ADF1;
					cursor: pointer;
				}
				.span1 {
					display: inline-block;
					height: 23px;
					padding: 0 10px;
					background: #0ABB75;
					border-radius: 4px;
					color: #fff;
				}
				.span2 {
					display: inline-block;
					padding: 5px 5px;
					background: #D9F3FD;
					border-radius: 4px;
					text-align: center;
					font-size: 14px;
					font-family: Arial;
					-webkit-line-clamp: 2;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					cursor: pointer;
					color: #00ADF1;
				}
				.span3 {
					display: inline-block;
					padding: 5px 5px;
					background: #F1002D;
					border-radius: 4px;
					text-align: center;
					font-size: 14px;
					color: #fff;
					cursor: pointer;
				}
			}
			.fenye {
				width: 100%;
				height: 60px;
				display: flex;
				justify-content: flex-end;
				box-sizing: border-box;
			}
		}
		.zhezhao {
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			position: absolute;
			z-index: 9999;
			background: rgba(0, 0, 0, 0.5);
			.querybox {
				width: 630px;
				background: #FFFFFF;
				border-radius: 5px;
				margin: auto;
				margin-top: 150px;
				.titles {
					font-size: 18px;
					font-family: Arial;
					font-weight: bold;
					color: #000000;
					line-height: 80px;
					height: 80px;
					text-align: left;
					padding-left: 30px;
					border-bottom: solid 1px #EDEDED;
				}
				.inpbox {
					width: 100%;
					margin-top: 30px;
					input {
						width: 575px;
						height: 50px;
						box-sizing: border-box;
						padding-left: 20px;
						border: solid 1px #EDEDED;
						font-size: 18px;
						outline: none;
						border-radius: 5px;
					}
					textarea {
						width: 575px;
						height: 210px;
						border: 1px solid #EDEDED;
						border-radius: 5px;
						outline: none;
						font-size: 18px;
						box-sizing: border-box;
						padding: 20px;
						margin-top: 20px;
						resize: none;
						overflow-y: hidden;
					}
				}
				.bombox {
					margin-top: 30px;
					height: 100px;
					border-top: solid 1px #EDEDED;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					box-sizing: border-box;
					padding-right: 30px;
					span {
						display: inline-block;
						width: 140px;
						height: 60px;
						border: 1px solid #00ADF1;
						border-radius: 5px;
						font-size: 18px;
						font-family: Arial;
						line-height: 60px;
						text-align: center;
						cursor: pointer;
					}
					.span01 {
						color: #00ADF1;
					}
					.span02 {
						color: #fff;
						background-color: #00ADF1;
						margin-left: 20px;
						
					}
				}
			}
		}
	}
	.phone {
		.bigbox {
			width: 100%;
			background: #fff;
			box-sizing: border-box;
			padding: 0 20px;
			.historys {
				font-size: 30px;
				font-family: Arial;
				font-weight: bold;
				color: #000000;
				line-height: 60px;
				text-align: left;
				border-bottom: solid 1px #F6F6F6;
			}
			.tablebox {
				width: 100%;
				.tabbox {
					width: 100%;
					border: solid 2px #e6e6e6;
					margin-top: 10px;
					border-radius: 10px;
					.itemtable {
						display: flex;
						min-height: 50px;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;
						padding: 0 20px;
						p {
							font-size: 26px;
							margin-right: 15px;
						}
						span {
							font-size: 26px;
						}
						img{
							width: 50px;
							height: 50px;
							margin: 0 5px;
							cursor: pointer;
						}
						.spans {
							color: #00ADF1;
						}
						.span1 {
							display: inline-block;
							padding: 0 10px;
							background: #0ABB75;
							border-radius: 4px;
							color: #fff;
						}
						.span2 {
							display: inline-block;
							padding: 5px 10px;
							background: #D9F3FD;
							border-radius: 4px;
							text-align: center;
							font-size: 26px;
							font-family: Arial;
							-webkit-line-clamp: 2;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
							cursor: pointer;
							color: #00ADF1;
						}
						.span3 {
							display: inline-block;
							padding: 5px 5px;
							background: #F1002D;
							border-radius: 4px;
							text-align: center;
							font-size: 26px;
							color: #fff;
							cursor: pointer;
						}
					}
				}
			}
			.fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 50px;
                        height: 50px;
                    }
                    :deep(.number) {
                        font-size: 40px !important;
                    }
                    :deep(.is-first) {
                        width: 50px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 40px !important;
                    }
                }
            }
		}
		.zhezhao {
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			position: fixed;
			z-index: 999;
			background: rgba(0, 0, 0, 0.7);
			.querybox {
				width: 75%;
				background: #FFFFFF;
				border-radius: 5px;
				margin: auto;
				margin-top: 40%;
				.titles {
					font-size: 30px;
					font-family: Arial;
					font-weight: bold;
					color: #000000;
					line-height: 80px;
					height: 80px;
					text-align: left;
					padding-left: 30px;
					border-bottom: solid 1px #EDEDED;
				}
				.inpbox {
					width: 100%;
					margin-top: 30px;
					input {
						width: 90%;
						height: 80px;
						box-sizing: border-box;
						padding-left: 20px;
						border: solid 2px #EDEDED;
						font-size: 28px;
						outline: none;
						border-radius: 5px;
					}
					textarea {
						width: 90%;
						height: 210px;
						border: 2px solid #EDEDED;
						border-radius: 5px;
						outline: none;
						font-size: 28px;
						box-sizing: border-box;
						padding: 20px;
						margin-top: 20px;
						resize: none;
						overflow-y: hidden;
					}
				}
				.bombox {
					margin-top: 30px;
					height: 140px;
					border-top: solid 2px #EDEDED;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					box-sizing: border-box;
					padding-right: 30px;
					span {
						display: inline-block;
						width: 200px;
						height: 80px;
						border: 2px solid #00ADF1;
						border-radius: 5px;
						font-size: 28px;
						font-family: Arial;
						line-height: 80px;
						text-align: center;
					}
					.span01 {
						color: #00ADF1;
					}
					.span02 {
						color: #fff;
						background-color: #00ADF1;
						margin-left: 20px;
						
					}
				}
			}
		}
	}
</style>