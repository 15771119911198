<template>
    <!-- 发货信息 -->
    <div class="pc">
        <div class="shippingbox">
            <div class="twobox">
                <div class="itemaddress" v-for="item in addressList" :key="item.id">
                    <div class="lefts">
                        <div class="itemtext">
                            <span>{{ $t('buyer.orderDetail.address') }}:</span>
                            <span>{{ item.address }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.postcode') }}:</span>
                            <span>{{ item.postal_code }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.city') }}:</span>
                            <span>{{ item.city }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.stateProvince') }}:</span>
                            <span>{{ item.state }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.nation') }}:</span>
                            <span>{{ item.country }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.phone') }}:</span>
                            <span>{{ item.phone }}</span>
                        </div>
                    </div>
                    <div class="rights" @click="setDefault(item.id)">
                        <img src="../../../assets/img/xuanze-yes.png" v-if="item.id == id">
                        <img src="../../../assets/img/xuanze-no.png" v-else>
                    </div>
                </div>
            </div>
            <div class="add" @click="tcshow = true">
                <span>+</span>
            </div>
            <div class="dibubox">
                <div class="leftfanhui" @click="fanhui">
                    <img src="../../../assets/img/zuojiantou-lan.png">
                    <span>{{ $t('buyer.myCart.returnToShop') }}</span>
                </div>
                <div class="rightbox" @click="toDelivery">
                    <span>{{ $t('buyer.myCart.continueToDeliveryInfo') }}</span>
                </div>
            </div>
            <!-- 弹窗 -->
            <div class="zhezhao" v-if="tcshow">
                <div class="tcbox">
                    <div class="newAddress">
                        <span>{{ $t('buyer.addAddress.addAddress') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="gbTanchuang">
                    </div>
                    <div class="addressbox">
                        <span>{{ $t('buyer.addAddress.address') }}</span>
                        <textarea v-model="address"></textarea>
                    </div>
                    <div class="posbox">
                        <span>{{ $t('buyer.addAddress.nation') }}</span>
                        <input type="text" v-model="country">
                    </div>
                    <div class="posbox">
                        <span>{{ $t('buyer.addAddress.stateProvince') }}</span>
                        <input type="text" v-model="state">
                    </div>
                    <div class="posbox">
                        <span>{{ $t('buyer.addAddress.city') }}</span>
                        <input type="text" v-model="city">
                    </div>
                    <div class="posbox">
                        <span>{{ $t('buyer.addAddress.postcode') }}</span>
                        <input type="text" v-model="postalCode">
                    </div>
                    <div class="posbox">
                        <span>{{ $t('buyer.addAddress.phone') }}</span>
                        <input type="text" v-model="phone">
                    </div>
                    <div class="savebox" @click="addAddress">
                        <span>{{ $t('buyer.addAddress.save') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class="shippingbox">
            <div class="twobox">
                <div class="itemaddress" v-for="item in addressList" :key="item.id">
                    <div class="lefts">
                        <div class="itemtext">
                            <span>{{ $t('buyer.orderDetail.address') }}:</span>
                            <span>{{ item.address }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.postcode') }}:</span>
                            <span>{{ item.postal_code }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.city') }}:</span>
                            <span>{{ item.city }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.stateProvince') }}:</span>
                            <span>{{ item.state }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.nation') }}:</span>
                            <span>{{ item.country }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.phone') }}:</span>
                            <span>{{ item.phone }}</span>
                        </div>
                    </div>
                    <div class="rights" @click="setDefault(item.id)">
                        <img src="../../../assets/img/xuanze-yes.png" v-if="item.id == id">
                        <img src="../../../assets/img/xuanze-no.png" v-else>
                    </div>
                </div>
            </div>
            <div class="add" @click="tcshow = true">
                <span>+</span>
            </div>
            <div class="dibubox">
                <div class="rightbox" @click="toDelivery">
                    <span>{{ $t('buyer.myCart.continueToDeliveryInfo') }}</span>
                </div>
                <div class="leftfanhui" @click="fanhui">
                    <img src="../../../assets/img/zuojiantou-lan.png">
                    <span>{{ $t('buyer.myCart.returnToShop') }}</span>
                </div>
            </div>
            <!-- 弹窗 -->
            <div class="zhezhao" v-if="tcshow">
                <div class="tcbox">
                    <div class="newAddress">
                        <span>{{ $t('buyer.addAddress.addAddress') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="gbTanchuang">
                    </div>
                    <div class="addressbox">
                        <span>{{ $t('buyer.addAddress.address') }}</span>
                        <textarea v-model="address"></textarea>
                    </div>
                    <div class="posbox">
                        <span>{{ $t('buyer.addAddress.nation') }}</span>
                        <input type="text" v-model="country">
                    </div>
                    <div class="posbox">
                        <span>{{ $t('buyer.addAddress.stateProvince') }}</span>
                        <input type="text" v-model="state">
                    </div>
                    <div class="posbox">
                        <span>{{ $t('buyer.addAddress.city') }}</span>
                        <input type="text" v-model="city">
                    </div>
                    <div class="posbox">
                        <span>{{ $t('buyer.addAddress.postcode') }}</span>
                        <input type="text" v-model="postalCode">
                    </div>
                    <div class="posbox">
                        <span>{{ $t('buyer.addAddress.phone') }}</span>
                        <input type="text" v-model="phone">
                    </div>
                    <div class="savebox" @click="addAddress">
                        <span>{{ $t('buyer.addAddress.save') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {orderAddress,addressAdd} from '@/api/buyerapi'
    import { ElMessage } from 'element-plus'
    export default {
        data(){
            return {
                tcshow: false,
                address: '', //地址
                country: '', //国家
                state: '', //州
                city: '',//城市
                postalCode: '', //邮编
                phone: '', //电话
                addressList: [], //地址列表
                id: '', //地址ID
            }
        },
        mounted(){
            this.getCartAddress()
        },
        methods: {
            // 获取地址
            async getCartAddress(){
                const {data: res} = await orderAddress()
                this.addressList = res.success
                console.log(res)
            },
            // 添加地址
            async addAddress(){
                if(this.address == '') {return ElMessage.error('Please enter the address')}
                if(this.country == '') {return ElMessage.error('Please enter the address')}
                if(this.state == '') {return ElMessage.error('Please enter the address')}
                if(this.city == '') {return ElMessage.error('Please enter the address')}
                if(this.phone == '') {return ElMessage.error('Please enter your phone number')}
                const {data: res} = await addressAdd({
                    address: this.address, //地址(Address)
                    country: this.country, //国家(Country)
                    state: this.state, //洲(State)
                    city: this.city, //市(City)
                    postal_code: this.postalCode, //邮编(Postal code)
                    phone: this.phone //电话(Phone)
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getCartAddress()
                    this.tcshow = false
                    this.address = ''
                    this.country = ''
                    this.state = ''
                    this.city = ''
                    this.postalCode = ''
                    this.phone = ''
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 关闭弹出
            gbTanchuang(){
                this.tcshow = false
                this.address = ''
                this.country = ''
                this.state = ''
                this.city = ''
                this.postalCode = ''
                this.phone = ''
            },
            // 选择收货地址
            setDefault(id){
                this.id = id
            },
            // 下一步
            toDelivery(){
                if(this.id == '') {
                    return ElMessage.error('Please select an address')
                }
                sessionStorage.setItem('id', this.id)
                this.$router.push('/index/cart/deliveryinfo')
            },
            fanhui(){
                this.$router.push('/index')
            },
        }
    }
</script>

<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
        .pc {
            display: block !important;
        }
        .phone {
            display: none !important;
        }
    }

    /* 竖屏*/
    @media all and (orientation : portrait) {
        .pc {
            display: none !important;
        }
        .phone {
            display: block !important;
        }
    }
    .pc {
        .shippingbox {
            width: 100%;
            background: #fff;
            margin-top: 35px;
            padding-bottom: 30px;
            .twobox {
                width: 100%;
                box-sizing: border-box;
                padding: 0 30px;
                display: flex;
                flex-wrap: wrap;
                .itemaddress {
                    width: 643px;
                    border: 1px solid #CECECE;
                    border-radius: 4px;
                    margin-right: 30px;
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 10px 20px;
                    position: relative;
                    .lefts {
                        line-height: 32px;
                        text-align: left;
                        font-size: 14px;
                        width: 540px;
                        span:nth-child(1){
                            font-family: Arial;
                            font-weight: 400;
                            color: #969696;
                        }
                        span:nth-child(2){
                            color: #000;
                            margin-left: 10px;
                        }
                    }
                    .rights {
                        position: absolute;
                        right: 10px;
                        top: 15px;
                        cursor: pointer;
                        img {
                            width: 30px;
                            height: 30px;
                        } 
                    }
                }
                .itemaddress:nth-of-type(2n + 0) {
                    margin-right: 0;
                }
            }
            .add {
                width: 645px;
                height: 95px;
                border: 1px solid #CECECE;
                border-radius: 5px;
                margin: auto;
                margin-top: 30px;
                text-align: center;
                line-height: 95px;
                font-size: 60px;
                font-weight: 800;
                cursor: pointer;
            }
            .dibubox {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 30px;
                margin-top: 30px;
                .leftfanhui {
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #00ADF1;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                .rightbox {
                    width: 190px;
                    height: 42px;
                    background: #00ADF1;
                    border-radius: 5px;
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 42px;
                    cursor: pointer;
                }
            }
            .zhezhao {
                position: fixed;
                z-index: 999999;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                .tcbox {
                    width: 630px;
                    padding-bottom: 30px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    margin: auto;
                    margin-top: 100px;
                    .newAddress {
                        width: 100%;
                        height: 75px;
                        font-size: 18px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #000000;
                        box-sizing: border-box;
                        padding: 0 30px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: solid 1px #EDEDED;
                        img {
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        }
                    }
                    .addressbox {
                        font-size: 14px;
                        font-family: Arial;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 30px;
                        margin-top: 20px;
                        textarea {
                            width: 470px;
                            height: 80px;
                            border: 1px solid #EDEDED;
                            border-radius: 5px;
                            outline: none;
                            resize: none;
                            box-sizing: border-box;
                            padding: 10px;
                        }
                    }
                    .posbox {
                        font-size: 14px;
                        font-family: Arial;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 30px;
                        margin-top: 20px;
                        input {
                            width: 470px;
                            height: 60px;
                            border: 1px solid #EDEDED;
                            border-radius: 5px;
                            font-size: 14px;
                            box-sizing: border-box;
                            padding: 0 15px;
                            outline: none;
                        }
                    }
                    .savebox {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 30px;
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 20px;
                        span {
                            display: inline-block;
                            width: 140px;
                            height: 60px;
                            background: #00ADF1;
                            border-radius: 5px;
                            text-align: center;
                            line-height: 60px;
                            font-size: 18px;
                            font-family: Arial;
                            color: #FFFFFF;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .phone {
        .shippingbox {
            width: 100%;
            background: #fff;
            margin-top: 35px;
            padding-bottom: 30px;
            .twobox {
                width: 100%;
                box-sizing: border-box;
                padding: 0 20px;
                display: flex;
                flex-wrap: wrap;
                .itemaddress {
                    width:100%;
                    border: 1px solid #CECECE;
                    border-radius: 4px;
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 10px 20px;
                    position: relative;
                    .lefts {
                        line-height: 45px;
                        text-align: left;
                        font-size: 24px;
                        width: 540px;
                        span:nth-child(1){
                            font-family: Arial;
                            font-weight: 400;
                            color: #969696;
                        }
                        span:nth-child(2){
                            color: #000;
                            margin-left: 10px;
                        }
                    }
                    .rights {
                        position: absolute;
                        right: 10px;
                        top: 15px;
                        cursor: pointer;
                        img {
                            width: 40px;
                            height: 40px;
                        } 
                    }
                }
                .itemaddress:nth-of-type(2n + 0) {
                    margin-right: 0;
                }
            }
            .add {
                width: 96%;
                height: 150px;
                border: 1px solid #CECECE;
                border-radius: 5px;
                margin: 0 20px;
                margin-top: 30px;
                text-align: center;
                line-height: 150px;
                font-size: 80px;
                font-weight: 800;
                cursor: pointer;
            }
            .dibubox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 0 30px;
                margin-top: 30px;
                .leftfanhui {
                    font-size: 26px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #00ADF1;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-top: 20px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                .rightbox {
                    width: 50%;
                    height: 80px;
                    background: #00ADF1;
                    border-radius: 5px;
                    font-size: 26px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 80px;
                }
            }
            .zhezhao {
                position: fixed;
                z-index: 999999;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                .tcbox {
                    width: 90%;
                    padding-bottom: 30px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    margin: auto;
                    margin-top: 100px;
                    .newAddress {
                        width: 100%;
                        height: 75px;
                        font-size: 28px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #000000;
                        box-sizing: border-box;
                        padding: 0 30px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: solid 1px #EDEDED;
                        img {
                            width: 40px;
                            height: 40px;
                            cursor: pointer;
                        }
                    }
                    .addressbox {
                        font-size: 24px;
                        font-family: Arial;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 30px;
                        margin-top: 20px;
                        textarea {
                            width: 470px;
                            height: 80px;
                            border: 3px solid #EDEDED;
                            border-radius: 5px;
                            outline: none;
                            resize: none;
                            box-sizing: border-box;
                            padding: 10px;
                            font-size: 24px;
                        }
                    }
                    .posbox {
                        font-size: 24px;
                        font-family: Arial;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 30px;
                        margin-top: 20px;
                        input {
                            width: 470px;
                            height: 80px;
                            border: 3px solid #EDEDED;
                            border-radius: 5px;
                            font-size: 24px;
                            box-sizing: border-box;
                            padding: 0 15px;
                            outline: none;
                        }
                    }
                    .savebox {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 30px;
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 20px;
                        span {
                            display: inline-block;
                            width: 240px;
                            height: 80px;
                            background: #00ADF1;
                            border-radius: 5px;
                            text-align: center;
                            line-height: 80px;
                            font-size: 28px;
                            font-family: Arial;
                            color: #FFFFFF;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
</style>