import request from "@/utils/request";

// 商品分类(卖家添加商品)
export const cateAll = () => {
    return request({
        url: "/v1/p/cate-all",
        method: 'POST',
    })
}

// 卖家个人信息
export const sellerInfo = () => {
    return request({
        url: "/v1/seller",
        method: 'POST'
    })
}

// 个人信息修改
export const sellerMp = (data) => {
    return request({
        url: "/v1/seller-mp",
        method: 'POST',
        data
    })
}
// 个人信息-加密提现设置添加和修改
export const sellerCws = (data) => {
    return request({
        url: "/v1/seller-cws",
        method: 'POST',
        data
    })
}

// 个人信息-加密提现设置删除
export const sellerDel = (data) => {
    return request({
        url: "/v1/seller-cws-del",
        method: 'POST',
        data
    })
}

// 个人信息-地址添加修改
export const adderssSet = (data) => {
    return request({
        url: "/v1/seller-address-set",
        method: 'POST',
        data
    })
}

// 个人信息-地址删除
export const adderssDel = () => {
    return request({
        url: "/v1/seller-address-del",
        method: 'POST'
    })
}

// 卖家自营产品列表
export const sellerGoods = (data) => {
    return request({
        url: "/v1/seller-goods",
        method: 'POST',
        data
    })
}

// 卖家添加修改商品
export const goodsEdit = (data) => {
    return request({
        url: "/v1/seller-goods-edit",
        method: 'POST',
        data
    })
}

// 删除商品
export const goodsDel = (data) => {
    return request({
        url: "/v1/seller-goods-del",
        method: 'POST',
        data
    })
}

// 排行10产品
export const Brand = () => {
    return request({
        url: "/v1/p/brand-top",
        method: 'POST',
    })
}

// 复制自营商品
export const goodsSelfCp = (data) => {
    return request({
        url: "/v1/seller-goods-self-cp",
        method: 'POST',
        data
    })
}

// 获取自营商品信息
export const goodsInfo = (data) => {
    return request({
        url: "/v1/seller-goods-info",
        method: 'POST',
        data
    })
}

// 商品列表精选推荐(Featured)
export const goodsFeatured = (data) => {
    return request({
        url: "/v1/seller-goods-featured",
        method: 'POST',
        data
    })
}

//商品列表发表（Published）
export const goodsPublished = (data) => {
    return request({
        url: "/v1/seller-goods-sj",
        method: 'POST',
        data
    })
}

// 平台商品列表
export const sysGoods = (data) => {
    return request({
        url: "/v1/seller-sys-goods",
        method: 'POST',
        data
    })
}

// 平台商品复制
export const sysGoodsCp = (data) => {
    return request({
        url: "/v1/seller-goods-self-sys-cp",
        method: 'POST',
        data
    })
}

// 店铺信息设置-获取信息
export const shopInfos = () => {
    return request({
        url: "/v1/seller-shop-info",
        method: 'POST'
    })
}

// 店铺信息设置-提交
export const shopSetting = (data) => {
    return request({
        url: "/v1/seller-shop-seting",
        method: 'POST',
        data
    })
}

// 订单管理-订单列表
export const orderList = (data) => {
    return request({
        url: "/v1/seller-order-list",
        method: 'POST',
        data
    })
}

// 订单管理-联系买家
export const contactBuyer = (data) => {
    return request({
        url: "/v1/seller-contact-buyer",
        method: 'POST',
        data
    })
}

// 订单管理-订单详情 /v1/seller-order-payment
export const orderDetails = (data) => {
    return request({
        url: "/v1/seller-order-details",
        method: 'POST',
        data
    })
}

// 订单管理-采购
export const orderPayment = (data) => {
    return request({
        url: "/v1/seller-order-payment",
        method: 'POST',
        data
    })
}

// 会话
export const converSeller = (data) => {
    return request({
        url: "/v1/seller-conversations-seller-list",
        method: 'POST',
        data
    })
}

// 会话详情
export const converContent = (data) => {
    return request({
        url: "/v1/seller-conversations-content-list",
        method: 'POST',
        data
    })
}

// 添加会话
export const contentAdd = (data) => {
    return request({
        url: "/v1/seller-conversations-content-add",
        method: 'POST',
        data
    })
}

// 退款列表
export const refundList = () => {
    return request({
        url: "/v1/seller-order-refund-list",
        method: 'POST'
    })
}

// 拒绝退款
export const orderRefuse = (data) => {
    return request({
        url: "/v1/seller-order-refuse",
        method: 'POST',
        data
    })
}

// 同意退款
export const orderAgree = (data) => {
    return request({
        url: "/v1/seller-order-agree",
        method: 'POST',
        data
    })
}

// 钱包统计
export const sellerWallet = () => {
    return request({
        url: "/v1/seller-wallet",
        method: 'POST'
    })
}

// 钱包充值记录
export const rechargeList = () => {
    return request({
        url: "/v1/seller-recharge-list",
        method: 'POST'
    })
}

// 钱包充值
export const rechargeAdd = (data) => {
    return request({
        url: "/v1/seller-recharge-add",
        method: 'POST',
        data
    })
}

// 可提现金额
export const pendingBalance = () => {
    return request({
        url: "/v1/seller-pending-balance",
        method: 'POST'
    })
}

// 提现记录
export const extractList = (data) => {
    return request({
        url: "/v1/seller-extract-list",
        method: 'POST',
        data
    })
}

// 提现到余额
export const sellerExtract = (data) => {
    return request({
        url: "/v1/seller-extract",
        method: 'POST',
        data
    })
}

// 提现到外部下拉列表
export const sellerExternalOption = () => {
    return request({
        url: "/v1/seller-external-option",
        method: 'POST'
    })
}

// 提现到外部
export const sellerExternal = (data) => {
    return request({
        url: "/v1/seller-external",
        method: 'POST',
        data
    })
}

// 我的贷款
export const sellerLoanCount = () => {
    return request({
        url: "/v1/seller-loan-count",
        method: 'POST'
    })
}

// 贷款额度申请
export const loanQuota = (data) => {
    return request({
        url: "/v1/seller-loan-quota",
        method: 'POST',
        data
    })
}

// 贷款申请
export const loanApply = (data) => {
    return request({
        url: "/v1/seller-loan-apply",
        method: 'POST',
        data
    })
}

// 贷款额度申请记录
export const loanQuotaBill = (data) => {
    return request({
        url: "/v1/seller-loan-quota-bill",
        method: 'POST',
        data
    })
}

// 贷款历史
export const loanBill = (data) => {
    return request({
        url: "/v1/seller-loan-bill",
        method: 'POST',
        data
    })
}

// 贷款历史记录-详情
export const loanDetails = (data) => {
    return request({
        url: "/v1/seller-loan-details",
        method: 'POST',
        data
    })
}

// 还款记录
export const loanRepaymentBill = (data) => {
    return request({
        url: "/v1/seller-loan-repayment-bill",
        method: 'POST',
        data
    })
}

// 还款
export const loanRepayment = (data) => {
    return request({
        url: "/v1/seller-loan-repayment",
        method: 'POST',
        data
    })
}

// 贷款申请记录
export const loanApplyBill = (data) => {
    return request({
        url: "/v1/seller-loan-apply-bill",
        method: 'POST',
        data
    })
}

// 支付历史记录
export const paymentHistory = (data) => {
    return request({
        url: "/v1/seller-wallet-payment-history",
        method: 'POST',
        data
    })
}

// 佣金历史记录
export const commissionHistory = (data) => {
    return request({
        url: "/v1/seller-wallet-commission-history",
        method: 'POST',
        data
    })
}

// 仪表盘
export const sellerDashboard = () => {
    return request({
        url: "/v1/seller-dashboard",
        method: 'POST'
    })
}

// 12大产品
export const sellerTopgoods = () => {
    return request({
        url: "/v1/seller-top-goods",
        method: 'POST'
    })
}

export const huihtongj = () => {
    return request({
        url: "/v1/seller-conversations-seller-count",
        method: 'POST'
    })
}

// 订单支付下拉选项
export const orderPay = () => {
    return request({
        url: "/v1/p/order-pay",
        method: 'POST'
    })
}

// 订单交付状态下拉选项
export const deliverStatus = () => {
    return request({
        url: "/v1/p/order-deliver-status",
        method: 'POST'
    })
}

// 订单数量
export const orderCount = () => {
    return request({
        url: "/v1/seller-order-pay-count",
        method: 'POST'
    })
}

// 消息通知列表
export const ordernewlist = () => {
    return request({
        url: "/v1/seller-order-new-list",
        method: 'POST'
    })
}