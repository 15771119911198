<template>
	<!-- 登陆 -->
	<div>
		<div class="pc">
			<div class="centbox">
				<div class="titles">
					<text>{{ $t('buyer.login.LoginAccount') }}</text>
				</div>
				<div class="inpbox">
					<input type="text" :placeholder="$t('buyer.login.email')" v-model="email">
				</div>
				<div class="inpbox">
					<input type="password" :placeholder="$t('buyer.login.password')" v-model="member_pwd">
				</div>
				<div class="groupbox">
					<div class="xzbox">
						<el-checkbox v-model="checked2">Remember Me</el-checkbox>
					</div>
					<text>Forgot password?</text>
				</div>
				<div class="create" @click="loginChange">
					<!-- <text>Login</text> -->
					<text>{{ $t('buyer.login.login') }}</text>
				</div>
				<div class="havex">
					<text>{{ $t('buyer.login.DontAccount') }}</text>
				</div>
				<div class="logoin" @click="toRegister">
					<text>{{ $t('buyer.login.registerNow') }}</text>
				</div>
			</div>
		</div>
		<div class="phone">
			<div class="centbox">
				<div class="titles">
					<text>{{ $t('buyer.login.LoginAccount') }}</text>
				</div>
				<div class="inpbox">
					<input type="text" :placeholder="$t('buyer.login.email')" v-model="email">
				</div>
				<div class="inpbox">
					<input type="password" :placeholder="$t('buyer.login.password')" v-model="member_pwd">
				</div>
				<div class="groupbox">
					<div class="xzbox">
						<el-checkbox v-model="checked2">Remember Me</el-checkbox>
					</div>
					<text>Forgot password?</text>
				</div>
				<div class="create" @click="loginChange">
					<!-- <text>Login</text> -->
					<text>{{ $t('buyer.login.login') }}</text>
				</div>
				<div class="havex">
					<text>{{ $t('buyer.login.DontAccount') }}</text>
				</div>
				<div class="logoin" @click="toRegister">
					<text>{{ $t('buyer.login.registerNow') }}</text>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {login} from '../../api/api.js'
import { ElMessage } from 'element-plus'
	export default {
		data() {
			return {
				checked2: '',
				email: '',
				member_pwd: ''
			}
		},
		onLoad() {
		
		},
		methods: {
			// 去注册
			toRegister(){
				this.$router.push('/index/signup')
			},
			//登录事件
			async loginChange(){
				if(this.email == '') {
					return ElMessage.error('Please enter your email address')
				}
				if(this.member_pwd == '') {
					return ElMessage.error('Please enter the password')
				}
				const {data: res} = await login({
					email: this.email,
					member_pwd: this.member_pwd
				})
				if(res.status == 200) {
					console.log('登录成功',res)
					// 登录成功储存身份信息
					if(res.success.member_role == 2) {
						sessionStorage.setItem('member_role', res.success.member_role)
						sessionStorage.setItem('member_token', res.success.member_token)
						this.$router.push('/Home')
					}else if(res.success.member_role == 3) {
						window.location.href = res.success.agent_url
					}else {
						sessionStorage.setItem('member_role', res.success.member_role)
						sessionStorage.setItem('member_token', res.success.member_token)
						this.$router.push('/index/mypages')
					}	
				}else {
					ElMessage.error(res.message)
				}
			}
		}
	}
</script>

<style lang="less" scoped>

	// 横屏
	@media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation : portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}

	.pc {
		.centbox {
			width: 450px;
			background-color: #fff;
			margin: auto;
			margin-top: 30px;
			box-sizing: border-box;
			padding: 30px 25px;
			.titles {
				font-size: 24px;
				font-family: Arial;
				font-weight: bold;
				width: 100%;
				text-align: center;
			}
			.inpbox {
				width: 100%;
				height: 45px;
				border: solid 1px #e6e6e6;
				border-radius: 5px;
				margin-top: 15px;
				display: flex;
				align-items: center;
				input {
					width: 100%;
					font-size: 14px;
					height: 45px;
					box-sizing: border-box;
					padding-left: 10px;
					border: none;
					outline: none;
				}
			}
			.groupbox {
				height: 40px;
				font-size: 12px;
				display: flex;
				color: #969696;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.xzbox {
					margin-top: 15px;
					/deep/ .el-checkbox {
						color: #969696;
					}
				}
				&>text {
					display: inline-block;
					margin-top: 13px;
					cursor: pointer;
				}
			}
			
			.create {
				cursor: pointer;
				width: 400px;
				height: 47px;
				background: #00ADF1;
				border-radius: 5px;
				font-size: 18px;
				font-family: Arial;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 47px;
				text-align: center;
				margin-top: 18px;
			}
			.havex {
				width: 100%;
				height: 14px;
				font-size: 14px;
				font-family: Arial;
				font-weight: 400;
				color: #969696;
				text-align: center;
				margin-top: 35px;
			}
			.logoin {
				cursor: pointer;
				width: 100%;
				height: 14px;
				font-size: 14px;
				font-family: Arial;
				font-weight: 400;
				color: #00ADF1;
				text-align: center;
				margin-top: 10px;
			}
		}
	}
	.phone {
		.centbox {
			width: 90%;
			background-color: #fff;
			margin: auto;
			margin-top: 30px;
			box-sizing: border-box;
			padding: 30px 25px;
			.titles {
				font-size: 40px;
				font-family: Arial;
				font-weight: bold;
				width: 100%;
				text-align: center;
				height: 100px;
				line-height: 100px;
			}
			.inpbox {
				width: 100%;
				height: 100px;
				border: solid 3px #e6e6e6;
				border-radius: 5px;
				margin-top: 15px;
				display: flex;
				align-items: center;
				input {
					width: 100%;
					height: 100px;
					font-size: 24px;
					height: 100px;
					box-sizing: border-box;
					padding-left: 10px;
					border: none;
					outline: none;
				}
			}
			.groupbox {
				height: 60px;
				font-size: 24px;
				display: flex;
				color: #969696;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 50px;
				.xzbox {
					margin-top: 15px;
					:deep(.el-checkbox) {
						color: #969696;
						--el-checkbox-font-size: 24px;
					}
					:deep(.el-checkbox__inner) {
						width: 30px;
						height: 30px;
						border: solid 2px #969696;
					}
				}
				&>text {
					display: inline-block;
					margin-top: 13px;
					cursor: pointer;
				}
			}
			
			.create {
				cursor: pointer;
				width: 100%;
				height: 80px;
				background: #00ADF1;
				border-radius: 5px;
				font-size: 30px;
				font-family: Arial;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 80px;
				text-align: center;
				margin-top: 18px;
			}
			.havex {
				width: 100%;
				height: 30px;
				font-size: 24px;
				font-family: Arial;
				font-weight: 400;
				color: #969696;
				text-align: center;
				margin-top: 35px;
			}
			.logoin {
				cursor: pointer;
				width: 100%;
				height: 30px;
				font-size: 24px;
				font-family: Arial;
				font-weight: 400;
				color: #00ADF1;
				text-align: center;
				margin-top: 20px;
			}
		}
	}
</style>
