<template>
    <div class="pc">
        <div class="titles">
            {{ $t('button.button.FlashDeals') }}
        </div>
        <div class="imgbox">
            <img src="../../assets/img/flash-01.png" alt="">
            <img src="../../assets/img/flash-02.png" alt="">
            <img src="../../assets/img/flash-03.png" alt="">
            <img src="../../assets/img/flash-04.png" alt="">
        </div>
    </div>
    <div class="phone">
        <div class="titles">
            {{ $t('button.button.FlashDeals') }}
        </div>
        <div class="imgbox">
            <img src="../../assets/img/flash-01.png" alt="">
            <img src="../../assets/img/flash-02.png" alt="">
            <img src="../../assets/img/flash-03.png" alt="">
            <img src="../../assets/img/flash-04.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
        .pc {
            display: block !important;
        }
        .phone {
            display: none !important;
        }
    }

    /* 竖屏*/
    @media all and (orientation: portrait) {
        .pc {
            display: none !important;
        }
        .phone {
            display: block !important;
        }
    }
    .pc {
        width: 1380px;
        margin: auto;
        .titles {
            width: 100%;
            height: 100px;
            line-height: 100px;
            text-align: left;
            font-size: 24px;
            font-family: Arial;
        }
        .imgbox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            img {
                width: 680px;
                height: 370px;
                overflow: hidden;
                margin-top: 10px;
            }
        }
    }
    .phone {
        width: 100%;
        margin: auto;
        .titles {
            width: 100%;
            height: 100px;
            line-height: 100px;
            text-align: center;
            font-size: 32px;
            font-family: Arial;
        }
        .imgbox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            img {
                width: 94%;
                height: auto;
                overflow: hidden;
                margin: auto;
                margin-top: 10px;
            }
        }
    }
</style>